import { networkRequest, get, put, post } from '@/utils/network.utils'
import deliberatePracticeApi from '@/apis/deliberatePracticeApi'
const getDeliberatePracticePeopleScore = async params => {
  const [error, result] = await networkRequest(() =>
    get(deliberatePracticeApi.getDeliberatePracticePeopleScore, params)
  )
  if (!error) {
    return result
  }
}
const updateDeliberatePracticePeopleScore = async params => {
  const [error, result] = await networkRequest(() =>
    put(deliberatePracticeApi.getDeliberatePracticePeopleScore, params)
  )
  if (!error) {
    return result
  }
}
const getDeliberatePracticeRank = async params => {
  const [error, result] = await networkRequest(() =>
    get(deliberatePracticeApi.getDeliberatePracticeRank, params)
  )
  if (!error) {
    return result
  }
}
const getCurrentActivityInfoSrc = async params => {
  const [error, result] = await networkRequest(() =>
    get(deliberatePracticeApi.getCurrentActivityInfo, params)
  )
  if (!error) {
    return result
  }
}
const signUpSrc = async (id, params) => {
  const [error, result] = await networkRequest(() =>
    post(deliberatePracticeApi.signUp.replace(':id', id), params)
  )
  if (!error) {
    return result
  }
}
const checkSignUpSrc = async (id, params) => {
  const [error, result] = await networkRequest(() =>
    get(deliberatePracticeApi.checkSignUp.replace(':id', id), params)
  )
  if (!error) {
    return result
  }
}
export {
  getDeliberatePracticePeopleScore,
  updateDeliberatePracticePeopleScore,
  getDeliberatePracticeRank,
  getCurrentActivityInfoSrc,
  checkSignUpSrc,
  signUpSrc
}
