import { networkRequest, get } from '@/utils/network.utils'
import otherApi from '@/apis/otherApi'

const getFAQListSer = async params => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.faq, params || null)
  )
  if (!error) {
    return result
  }
}
const instructionTags = async () => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.instructionTags)
  )
  if (!error) {
    return result
  }
}
const instructionGroupDetails = async id => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.instructionGroupDetails.replace(/:id/, id))
  )
  if (!error) {
    return result
  }
}
const instructionGroupLists = async params => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.instructionGroupLists, { ...params })
  )
  if (!error) {
    return result
  }
}
const instructionContext = async params => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.instructionContext, { ...params })
  )
  if (!error) {
    return result
  }
}
const checkCouponServer = async params => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.checkCouponInterface, { ...params })
  )
  if (!error) {
    return result
  }
}
const stayPageTimeServer = async params => {
  // const [error, result] = await networkRequest(() =>
  //   post(otherApi.stayPageTime, params)
  // )
  // if (!error) {
  //   return result
  // }
}
const convertTextToAudioServer = async params => {
  const [error, result] = await networkRequest(() =>
    get(otherApi.convertTextToAudio, params)
  )
  if (!error) {
    return result
  }
}
export {
  getFAQListSer,
  instructionContext,
  instructionGroupLists,
  instructionGroupDetails,
  instructionTags,
  checkCouponServer,
  stayPageTimeServer,
  convertTextToAudioServer
}
