import { getResourceSign, getVideoDetails } from '@/services/homeServices'
import _ from 'lodash'
export default {
  namespace: 'video',
  state: {
    currentChooseVideo: {},
    videoLoading: true
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(async ({ pathname }) => {
        try {
        } catch (err) {}
      })
    }
  },
  effects: {
    *chooseTopicVideo(
      { courseId, chapterId, topicId, videoId },
      { call, put }
    ) {
      yield put({
        type: 'videoLoading',
        videoLoading: true
      })
      const params = {
        courseId,
        chapterId,
        topicId
      }
      let videoInfo = yield call(getVideoDetails, videoId, params)
      // 鉴权
      const track = yield call(getResourceSign, { path: videoInfo.subtitleUrl })
      videoInfo = {
        ...videoInfo,
        subtitleUrl: _.get(track, 'url', '')
      }
      yield put({
        type: 'updateVideoInfo',
        videoInfo,
        videoLoading: false
      })
    }
  },
  reducers: {
    updateVideoInfo(state, { videoInfo, videoLoading }) {
      return {
        ...state,
        currentChooseVideo: {
          ...videoInfo
        },
        videoLoading
      }
    },
    clearChoosedVideoInfo(state, { nullObj = {} }) {
      return {
        ...state,
        currentChooseVideo: {
          ...nullObj
        }
      }
    },
    videoLoading(state, { videoLoading }) {
      return {
        ...state,
        videoLoading
      }
    }
  }
}
