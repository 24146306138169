import OnionModal from '@/components/OnionModal/OnionModal'
import RouteWithSubRoutes from '@/components/RouteWithSubRoutes'
import '@/index.scss'
import routes, { routesMap } from '@/router'
import BackgroundAnimation from '@/screens/Main/components/BackgroundAnimation/BackgroundAnimation'
import ErrorBoundary from '@/screens/Main/components/ErrorBoundary/ErrorBoundary'
import StayPageTime from '@/screens/Main/components/StayPageTime/StayPageTime'
import { isIOS, isShowBoswerModal } from '@/utils/tool.utils'
import { ConfigProvider, message } from 'antd'
import zhTW from 'antd/lib/locale-provider/zh_TW'
import { routerRedux } from 'dva'
import 'moment/locale/zh-tw'
import moment from 'moment/moment'
import pathToRegexp from 'path-to-regexp'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import mainCss from './main.module.scss'

moment.locale('zh-tw')

const { ConnectedRouter } = routerRedux

const Main = function({ history, showPrivateTips, app }) {
  const [visible, setVisible] = useState(false)
  const [showColor, setShowColor] = useState(false)
  const [showBg, setShowBg] = useState(false)
  const cachePath = useRef(new Map())
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-143255211-1')
      history.listen(({ pathname, search }) => {
        ReactGA.pageview(pathname + search)
      })
    }
    history.listen(({ pathname }) => {
      const search = window.location.hash.split('?')[1]
      const urlParams = new URLSearchParams(search)
      const token = urlParams.get('code')
      if (pathname === '/guide-home' && token) {
        history.push({
          pathname: '/',
          search: `?code=${token}`
        })
        return
      }
      if (cachePath && cachePath.current && cachePath.current.has(pathname)) {
        setShowBg(cachePath.current.get(pathname))
      } else {
        routesMap.forEach((v, i) => {
          if (
            !cachePath.current.has(pathname) &&
            pathToRegexp(v.path).test(pathname)
          ) {
            setShowBg(v.bg)
            cachePath.current.set(pathname, v.bg)
          }
        })
      }
    })
    isShowBoswerModal() && setVisible(true)
    function copy(e) {
      if (!window.location.href.endsWith('/home/good-list')) {
        e.clipboardData.setData('text/plain', window.location.origin)
        e.preventDefault()
        message.success(
          <span className={mainCss.copy_success}>複製成功</span>,
          1
        )
      }
    }
    window.document.addEventListener('copy', copy, false)
    return function() {
      window.document.removeEventListener('copy', copy, false)
    }
  }, [app, history])
  const handleOk = () => {
    setVisible(false)
  }
  const copy = () => {
    const textArea = window.document.createElement('textArea')
    textArea.value = window.location.origin
    document.body.appendChild(textArea)
    if (isIOS) {
      const range = window.document.createRange()
      range.selectNodeContents(textArea)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
    if (window.document.execCommand) {
      window.document.execCommand('copy')
      document.body.removeChild(textArea)
    }
  }
  return (
    <ErrorBoundary>
      <div
        className={`${showPrivateTips ? 'main-block__show-private-tips' : ''}`}
      >
        <StayPageTime history={history}>
          <BackgroundAnimation showBg={showBg} />
          <ConfigProvider locale={zhTW}>
            <Suspense fallback={null}>
              {/* {!showServiceContact && <InitServiceContact />} */}
              <ConnectedRouter history={history}>
                <RouteWithSubRoutes
                  routes={routes(app)}
                  history={history}
                  app={app}
                />
              </ConnectedRouter>
            </Suspense>
          </ConfigProvider>
        </StayPageTime>
      </div>
      <OnionModal
        title="蔥寶提示"
        onOk={handleOk}
        visible={visible}
        onCancel={() => setVisible(false)}
        sureText={'關閉彈窗'}
        showCancelButton={false}
      >
        為了更好的學習體驗，蔥寶建議您使用Google Chrome瀏覽器訪問“洋蔥學園”
        <span
          className={mainCss.copy}
          onClick={copy}
          style={{ color: showColor ? 'rgba(3, 110, 212, 1)' : '' }}
          onMouseEnter={() => setShowColor(true)}
          onMouseLeave={() => setShowColor(false)}
        >
          <u>複製網站連結</u>
        </span>
      </OnionModal>
    </ErrorBoundary>
  )
}
export default Main
