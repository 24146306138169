import { logout } from '@/services/homeServices'
const QINZIURL = process.env.REACT_APP_QIN_ZI_LOGOUT_URL
const encodeUrl = encodeURIComponent(process.env.REACT_APP_YANG_CONG_URL)
const logoutAction = () => {
  logout()
    .then(() => {
      window.localStorage.setItem('user', 'older')
      window.location.href = `${QINZIURL}?targetUrl=${encodeUrl}`
    })
    .catch(e => e)
}

export { logoutAction }
