/**
 *  @Author PandaCure
 *  @DATE 2020/4/20
 *  @Project tw-website
 */
import titleImage from '@/assets/deliberate-practice/modal_sign_success_title.png'
import lineQrcode from '@/assets/guide-home/line_qrcode.png'
import { connect } from 'dva'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { lazy, memo, useMemo } from 'react'
import css from './index.module.scss'
const OnionModal = lazy(() => import('@/components/OnionModal/OnionModal'))
const localToText = {
  plus: '加法',
  subtract: '減法',
  multi: '乘法',
  division: '除法'
}
const SignUpSuccess = ({
  signUpSuccessVisible,
  setSignUpSuccessVisible,
  activityInfo
}) => {
  const type = useMemo(() => {
    const key = _.get(activityInfo, 'content.0.type', 'plus')
    return localToText[key]
  }, [activityInfo])
  const time = useMemo(() => {
    const endTime = _.get(activityInfo, 'endTime', new Date())
    return moment(new Date(endTime)).format('YYYY年MM月DD日')
  }, [activityInfo])
  return (
    <OnionModal visible={signUpSuccessVisible} type={'free'}>
      <div className={css.model}>
        <div
          className={css.close}
          onClick={() => setSignUpSuccessVisible(false)}
        />
        <img src={titleImage} alt="titleImage" className={css.title} />
        <div className={css.code_block}>
          <div className={css.code_intro}>
            {`${time}期間，在洋蔥學園“試煉場”中的${type}挑戰獲得成績最高即可獲得超值好禮，完全免費，只要是洋蔥用戶都可以參加哦！`}
          </div>
          <img src={lineQrcode} alt="lineQrcode" className={css.code} />
          <div className={css.tag}>掃描QR code進入“洋蔥學園”官方帳號</div>
        </div>
      </div>
    </OnionModal>
  )
}
SignUpSuccess.propTypes = {
  signUpSuccessVisible: PropTypes.bool.isRequired,
  setSignUpSuccessVisible: PropTypes.func.isRequired
}
export default connect()(memo(SignUpSuccess))
