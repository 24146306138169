import ReactGA from 'react-ga'
const trackID = 'UA-143255211-1'
const buryPoint = option => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(trackID)
    ReactGA.event({
      ...option
    })
  }
}
export default buryPoint
