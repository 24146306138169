/**
 *  @Author PandaCure
 *  @DATE 2020/5/6
 *  @Project tw-website
 */
import React, { memo, useCallback, useEffect, useRef } from 'react'
import { connect, useDispatch } from 'dva'
import { getRouterData } from '@/utils/tool.utils'
import pathToRegexp from 'path-to-regexp'
const StayPageTime = ({ children, history }) => {
  const dispatch = useDispatch()
  // 上传
  const uploadTime = useCallback(
    (entryTime, pageName) => {
      const currentTime = new Date().getTime()
      const stayTime = currentTime - entryTime
      dispatch({
        type: 'common/stayPageTime',
        stayTime,
        pageName
      })
      window.localStorage.setItem('entry-page-time', currentTime + '')
    },
    [dispatch]
  )
  const getURLInfo = () => {
    let currentPageHref
    let pageName
    let currentPageInfo = window.localStorage.getItem('currentPageHref')
    if (currentPageInfo) {
      currentPageInfo = JSON.parse(currentPageInfo)
      currentPageHref = currentPageInfo.path
      pageName = currentPageInfo.name
    }
    return [currentPageHref, pageName]
  }
  const firstEntryPage = useRef(0)
  useEffect(() => {
    const [currentPageHref] = getURLInfo()
    const currentHref = new URL(window.location.href)
    if (
      currentPageHref &&
      pathToRegexp(currentPageHref).test(currentHref.hash.slice(1))
    ) {
      // 强刷 恢复时间
    } else {
      window.localStorage.setItem('entry-page-time', new Date().getTime() + '')
    }
    let entryPageTime
    let pageName
    history.listen(({ pathname }) => {
      entryPageTime = +window.localStorage.getItem('entry-page-time')
      const [currentPageHref, pageNameSpace] = getURLInfo()
      pageName = pageNameSpace
      if (
        firstEntryPage.current !== 0 &&
        !pathToRegexp(currentPageHref).test(pathname)
      ) {
        // 上传
        uploadTime(entryPageTime, pageName)
      }
      firstEntryPage.current++
      let count = 0
      let a
      let b
      getRouterData().forEach(v => {
        if (pathToRegexp(v.path).test(pathname)) {
          if (v.path === pathname) {
            count++
            a = v
          }
          b = v
        }
      })
      window.localStorage.setItem(
        'currentPageHref',
        JSON.stringify({
          name: count ? a?.name : b?.name,
          path: count ? a?.path : b?.path
        })
      )
    })
    return () => {
      uploadTime(entryPageTime, pageName)
    }
  }, [history, uploadTime])
  return <>{children}</>
}

export default connect()(memo(StayPageTime))
