// 刻意练习 modal
import {
  getDeliberatePracticePeopleScore,
  updateDeliberatePracticePeopleScore,
  getDeliberatePracticeRank,
  checkSignUpSrc,
  signUpSrc,
  getCurrentActivityInfoSrc
} from '@/services/deliberatePracticeServices'
import lodash from 'lodash'
export default {
  namespace: 'dp',
  state: {
    currentRecordScore: new Map(),
    rank: new Map(),
    signUpStatus: false,
    activityInfo: {},
    currentUserRank: {},
    currentActivityUserRank: {},
    modalRankData: new Map(),
    deliberateStatus: false
  },
  effects: {
    *getCurrentPeopleScore({ types, level }, { call, put, all }) {
      let currentRecordScore
      let data
      data = level.reduce((init, value, index) => {
        init.set(`${types}_level_${index + 1}`, { score: 0 })
        return init
      }, new Map())
      yield put({ type: 'setCurrentPeopleScore', data })
      try {
        currentRecordScore = yield all(
          level.sort().map(l => {
            return call(getDeliberatePracticePeopleScore, {
              type: types,
              level: l
            })
          })
        )
        if (currentRecordScore) {
          data = currentRecordScore.reduce((init, v, i) => {
            init.set(`${types}_level_${i + 1}`, v)
            return init
          }, new Map())
        }
      } catch (e) {
      } finally {
        yield put({
          type: 'setCurrentPeopleScore',
          data
        })
      }
    },
    *updateSelfScore({ types, score, duration, name, level, cid }, { call }) {
      yield call(updateDeliberatePracticePeopleScore, {
        type: types,
        score,
        duration,
        name,
        level,
        cid
      })
    },
    *getRank({ types, level }, { call, put, all }) {
      const rank = yield all(
        level
          .sort()
          .map(l => call(getDeliberatePracticeRank, { type: types, level: l }))
      )
      const data = rank.reduce((init, v, i) => {
        init.set(`${types}_level_${i + 1}`, lodash.get(v, 'personals', []))
        return init
      }, new Map())
      yield put({
        type: 'setRank',
        rank: data
      })
    },
    *checkSignUp({ id }, { call, put }) {
      const result = yield call(checkSignUpSrc, id)
      // 处理接口返回数据格式问题
      yield put({
        type: 'updateSignUpStatus',
        status: 'sign' in Object.create(result) ? result.sign : true
      })
    },
    *signUpActivity({ id }, { call, put }) {
      yield call(signUpSrc, id)
      yield put({
        type: 'checkSignUp',
        id
      })
    },
    *getActivityDetails(_, { call, put }) {
      const result = yield call(getCurrentActivityInfoSrc)
      if (!lodash.get(result, 'id', false)) {
        yield put({
          type: 'updateActivityStatus',
          status: false
        })
        return false
      }
      yield put({
        type: 'updateActivityStatus',
        status: true
      })
      yield put({
        type: 'checkSignUp',
        id: result.id
      })
      yield put({
        type: 'updateActivityInfo',
        result
      })
      yield put({ type: 'handleActivityMoreGroupScore' })
    },
    *handleMoreGroupScore({ types, level }, { call, put, all }) {
      const result = yield all(
        level.sort().map(l =>
          call(getDeliberatePracticePeopleScore, {
            type: types,
            level: l
          })
        )
      )
      const handleResult = result.reduce((init, v) => {
        return Object.assign({}, init, {
          [v.level]: {
            score: v.score,
            rank: v.rank,
            nickname: v.nickname,
            participate: v.participate
          }
        })
      }, {})
      yield put({
        type: 'updateCurrentUserAllLevelScore',
        result: handleResult
      })
    },
    *handleActivityMoreGroupScore(_, { call, put, all, select }) {
      yield put({
        type: 'clearLevelScore'
      })
      const activityInfo = yield select(state => state.dp.activityInfo)
      const types = lodash.get(activityInfo, 'content.0.type', 'plus')
      const level = lodash.get(activityInfo, 'content.0.level', ['level1'])
      const cid = lodash.get(activityInfo, 'id', '')
      const result = yield all(
        level.sort().map(l =>
          call(getDeliberatePracticePeopleScore, {
            type: types,
            level: l,
            cid
          })
        )
      )
      const handleResult = result.reduce((init, v) => {
        return Object.assign({}, init, {
          [v.level]: {
            score: v.score,
            rank: v.rank,
            nickname: v.nickname,
            participate: v.participate
          }
        })
      }, {})
      yield put({
        type: 'updateCurrentActivityUserAllLevelScore',
        result: handleResult
      })
    },
    *handleMoreGroupRank({ types, level, cid }, { call, put, all }) {
      const result = yield all(
        level
          .sort()
          .map(l =>
            call(getDeliberatePracticeRank, { type: types, level: l, cid })
          )
      )
      // 分组数据可能不准确 嘿嘿 So....
      const data = new Map()
      result.map((v, i) => data.set(`${types}_${i + 1}`, v.personals))
      yield put({
        type: 'updateRankLevelData',
        result: data
      })
    }
  },
  reducers: {
    setCurrentPeopleScore(state, { data }) {
      return {
        ...state,
        currentRecordScore: data
      }
    },
    setRank(state, { rank }) {
      return {
        ...state,
        rank
      }
    },
    updateSignUpStatus(state, { status }) {
      return {
        ...state,
        signUpStatus: status
      }
    },
    updateActivityInfo(state, { result }) {
      return {
        ...state,
        activityInfo: result
      }
    },
    updateCurrentUserAllLevelScore(state, { result }) {
      return {
        ...state,
        currentUserRank: result
      }
    },
    updateCurrentActivityUserAllLevelScore(state, { result }) {
      return {
        ...state,
        currentActivityUserRank: result
      }
    },
    updateRankLevelData(state, { result }) {
      return {
        ...state,
        modalRankData: result
      }
    },
    updateActivityStatus(state, { status }) {
      return {
        ...state,
        deliberateStatus: status
      }
    },
    clearLevelScore(state) {
      return {
        ...state,
        currentUserRank: {}
      }
    }
  }
}
