import React, { lazy } from 'react'
import { dynamic } from 'dva'
import GuidePage from '@/screens/GuidePage'
import Loading from '@/components/Loading.common'
import DeliberatePracticeEntry from '@/screens/DeliberatePracticeEntry/DeliberatePracticeEntry'
import DeliberatePracticeDetail from '@/screens/DeliberatePracticeDetail/DeliberatePracticeDetail'
// import DeliberatePractice from '@/screens/DeliberatePractice/DeliberatePractice'
import _ from 'lodash'
// const GoodListPage = lazy(() => import('@/screens/GoodListPage/GoodListPage'))
const LoginPage = lazy(() => import('@/screens/LoginPage'))
const HomePage = lazy(() => import('@/screens/Home'))
const VideoPage = lazy(() => import('@/screens/VideoPage'))
const PracticePage = lazy(() => import('@/screens/OldPracticePage'))
const NewPracticePage = lazy(() =>
  import('@/screens/NewPracticePage/NewPracticePage')
)
const NewPracticeComponent = lazy(() =>
  import('@/screens/NewPracticePage/components/NewPracticeComponent')
)
const ExaminationPage = lazy(() =>
  import('@/screens/ExaminationPage/ExaminationPage')
)
const ExaminationFinishedPage = lazy(() =>
  import('@/screens/ExaminationFinishedPage/ExaminationFinishedPage')
)
const ExaminationConditionPage = lazy(() =>
  import('@/screens/ExaminationConditionPage/ExaminationConditionPage')
)
const FinishedPractice = lazy(() => import('@/screens/FinishedPractice'))
const CourseContent = lazy(() =>
  import('@/screens/Home/components/CourseContent')
)
const About = lazy(() => import('@/screens/About/About'))
const PrivateTipsPage = lazy(() =>
  import('@/screens/PrivateTipsPage/PrivateTipsPage')
)
// static-onion -> 静态蓝色背景
// dynamic-onion -> 动态蓝色背景
// none -> 无背景
// TODO screens文件夹下按照路由划分
const routes = app => {
  return [
    {
      exact: true,
      path: '/',
      component: GuidePage,
      bg: 'static-onion',
      name: '驗證登錄中轉頁'
    },
    {
      path: '/guide-home',
      component: dynamic({
        app,
        models: () => [import('@/modals/guideHome.modal')],
        component: () => import('@/screens/GuideHomePage/GuideHomePage'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'dynamic-onion',
      name: '首頁'
    },
    {
      path: '/faq',
      component: dynamic({
        app,
        models: () => [import('@/modals/faq.modal')],
        component: () => import('@/screens/FAQPage/FAQ'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'static-onion',
      name: 'FAQ'
    },
    {
      path: '/login',
      component: LoginPage,
      bg: 'none', // TODO 可以做动画图片缓存 但会导致首页加载缓慢
      name: '登入頁'
    },
    {
      path: '/private',
      component: PrivateTipsPage,
      bg: 'static-onion',
      name: '隱式聲明'
    },
    {
      path: '/home',
      component: HomePage,
      bg: 'static-onion',
      children: true,
      nav: {
        exclude: ['/home/good-list']
      },
      name: '學習中轉頁'
    },
    {
      path: '/deliberate-practice',
      component: dynamic({
        app,
        component: () =>
          import('@/screens/DeliberatePractice/DeliberatePractice'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'none',
      name: '試煉場中轉頁'
    },
    {
      path: '/new-practice',
      component: NewPracticePage,
      bg: 'none',
      name: '新答題中轉頁'
    }
  ]
}
const routerHomePath = app => {
  return [
    {
      path: '/home/good-list',
      component: dynamic({
        app,
        models: () => [import('@/modals/shop.modal')],
        component: () => import('@/screens/GoodListPage/GoodListPage'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'none',
      name: '商城頁'
    },
    {
      path: '/home/course/:courseId/chapter/:chapterId/topic/:id/video',
      component: VideoPage,
      bg: 'static-onion',
      name: '視頻頁'
    },
    {
      path:
        '/home/course/:courseId/chapter/:chapterId/topic/:id/practice/finished',
      component: FinishedPractice,
      bg: 'static-onion',
      name: '課後練習完成頁'
    },
    {
      path: '/home/course/:courseId/chapter/:chapterId/topic/:id/practice',
      component: PracticePage,
      bg: 'static-onion',
      name: '課後習題頁'
    },
    {
      path:
        '/home/course/:courseId/chapter/:chapterId/exam/:examId/examination/finished',
      component: ExaminationFinishedPage,
      bg: 'static-onion',
      name: '測驗完成頁'
    },
    {
      path:
        '/home/course/:courseId/chapter/:chapterId/exam/:examId/examination/condition',
      component: ExaminationConditionPage,
      bg: 'static-onion',
      name: '測驗報告頁'
    },
    {
      path:
        '/home/course/:courseId/chapter/:chapterId/exam/:examId/examination',
      component: ExaminationPage,
      bg: 'static-onion',
      name: '測驗頁'
    },
    {
      path: '/home/course_description',
      component: dynamic({
        app,
        models: () => [import('@/modals/instruction.modal')],
        component: () =>
          import('@/screens/LearningInstructions/LearningInstructions'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'static-onion',
      name: '課程內容介紹頁'
    },
    {
      path: '/home/about',
      component: About,
      bg: 'static-onion',
      name: '了解洋蔥頁'
    },
    {
      path: '/home/learning-condition-entry',
      component: dynamic({
        app,
        models: () => [import('@/modals/guideHome.modal')],
        component: () =>
          import('@/screens/LearningConditionEntry/LearningConditionEntry'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'static-onion',
      name: '學情入口頁'
    },
    {
      path: '/home/learning_condition',
      component: dynamic({
        app,
        models: () => [import('@/modals/learningCondition.modal')],
        component: () =>
          import('@/screens/LearningCondition/LearningCondition'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'static-onion',
      name: '學情報告'
    },
    {
      path: '/home/learning_weakness',
      component: dynamic({
        app,
        models: () => [import('@/modals/learningCondition.modal')],
        component: () => import('@/screens/LearningWeakness/LearningWeakness'),
        LoadingComponent: () => <Loading show />
      }),
      bg: 'static-onion',
      name: '查漏補缺'
    },
    {
      path: '/home/:id',
      component: CourseContent,
      exact: true,
      bg: 'static-onion',
      name: '學習中心'
    }
  ]
}
const routerDeliberatePracticePath = app => {
  return [
    {
      path: '/deliberate-practice/entry',
      component: DeliberatePracticeEntry,
      bg: 'none',
      name: '試煉場入口'
    },
    {
      path: '/deliberate-practice/detail/:type',
      component: DeliberatePracticeDetail,
      bg: 'none',
      name: '試煉場做題'
    }
  ]
}
const routerPracticePath = app => {
  return [
    {
      path: '/new-practice/course/:courseId/chapter/:chapterId/topic/:topicId',
      component: NewPracticeComponent,
      bg: 'none',
      name: '新答題頁'
    }
  ]
}
const routesMap = new Map()
const getRoutesMap = routes => {
  if (!_.isArray(routes)) return false
  routes.forEach(r => {
    if (r.routes) getRoutesMap(r.routes)
    routesMap.set(r.path, { bg: r.bg, path: r.path })
  })
}
getRoutesMap([
  ...routes(null),
  ...routerHomePath(null),
  ...routerDeliberatePracticePath(null)
])
export default routes
export {
  routesMap,
  routerHomePath,
  routerDeliberatePracticePath,
  routerPracticePath
}
