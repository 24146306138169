import errorImage from '@/assets/status/error.svg'
import { logoutAction } from '@/utils/logout.utils'
import React, { Component } from 'react'
import errorCss from './error.module.scss'
const isOpen = process.env.REACT_APP_DOMIN !== 'development'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: '',
      info: ''
    }
  }

  componentDidMount() {}

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    this.setState({
      error,
      info
    })
    if (isOpen) {
      window.predem.sendEvent('React异常捕获', {
        error,
        info,
        os: window.navigator.userAgent
      })
    }
    setTimeout(() => {
      process.env.NODE_ENV === 'development' && this.home()
    }, 3000)
  }

  home = () => {
    console.log('从 ErrorBoundary.js -> home 中登出')
    logoutAction()
    // 导致代码重复发生
    // window.location.href = window.location.origin
    // this.setState({
    //   hasError: false
    // })
  }

  render() {
    const { hasError, error, info } = this.state
    if (hasError && process.env.NODE_ENV === 'development') {
      return (
        <div className={errorCss.container}>
          <div className={errorCss.content}>
            <img src={errorImage} alt="发生意外错误" />
            <p className={errorCss.error_text}>{error + '\n' + info}</p>
            <div className={errorCss.button} onClick={this.home}>
              回到首頁
            </div>
          </div>
        </div>
      )
    } else if (hasError) {
      return <div />
    }

    return this.props.children
  }
}

export default ErrorBoundary
