import { checkBills, getUserInfo, login } from '@/services/homeServices'
import { routerRedux } from 'dva'

export default {
  namespace: 'common',
  state: {
    userInfo: {},
    isOlder: false
  },
  effects: {
    *userInfo({ pid, token }, { call, put }) {
      try {
        // 如果有 token 则优先通过 token 获取用户信息
        if (token) {
          const loginResult = yield call(login, { token })
          if (!loginResult) return false
        }
        // 使用 user/me 接口获取用户信息
        console.log('將要獲取用戶信息')
        const userInfoResult = yield call(getUserInfo)
        // 如果存在成功获取用户信息，则
        if (userInfoResult) {
          // 更新用户信息
          yield put({
            type: 'updateUserInfo',
            userInfoResult
          })
          // 导航至主页
          yield put(routerRedux.push('/guide-home'))
          // 变换背景图片
          yield put({
            type: 'ui/changeBg',
            status: true
          })
          // 回来的时候如果带有订单ID，则使用订单ID去后端申请验证，以方便订单即时生效
          if (pid) {
            yield call(checkBills, pid)
          }
        }
      } catch (e) {
        // 如果遇到任何错误，则跳出到登录页
        console.log('獲取用戶信息出錯 :: ', e)
        yield put(routerRedux.push('/login'))
      }
    },
    // *stayPageTime({ stayTime, pageName }, { call }) {
    //   yield call(stayPageTimeServer, { page: pageName, duration: stayTime })
    // }
  },
  reducers: {
    updateUserInfo(state, { userInfoResult }) {
      return {
        ...state,
        userInfo: userInfoResult
      }
    },
    updateOlderUser(state, { older }) {
      return {
        ...state,
        isOlder: older
      }
    }
  }
}
