/**
 *  @Author PandaCure
 *  @DATE 2020/1/2
 *  @Project tw-website
 */
import React, { memo, useEffect, useState } from 'react'
import css from './index.module.scss'
import { connect } from 'dva'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
const QuadrangleTab = ({ tabData, dec, changeTab, changeValue }) => {
  const [index, setIndex] = useState(0)
  const handleActiveTab = (key, v) => {
    changeTab(key, v)
    setIndex(key)
  }
  useEffect(() => {
    if (changeValue && changeValue.key > -1) setIndex(changeValue.key)
  }, [changeValue])
  return (
    <>
      <ul className={css[`${dec}-tab`]}>
        {_.map(tabData, (v, i) => (
          <li
            className={classNames(
              i === index ? css[`${dec}--tab_item1`] : '',
              css[`${dec}--tab_item2`],
              css[`${dec}--tab_item`]
            )}
            key={i}
          />
        ))}
        <ul className={css[`${dec}-hack`]}>
          {_.map(tabData, (v, i) => (
            <li
              className={css[`${dec}--hack_item`]}
              key={i}
              onClick={() => handleActiveTab(i, v)}
            >
              {v}
            </li>
          ))}
        </ul>
      </ul>
    </>
  )
}
QuadrangleTab.propTypes = {
  tabData: PropTypes.array.isRequired,
  dec: PropTypes.oneOf(['row', 'column']).isRequired
}
export default connect()(memo(QuadrangleTab))
