/**
 *  @Author PandaCure
 *  @DATE 2019-10-09
 *  @Project tw-website
 */
import React from 'react'
import bg from './StaticBgAnimation.module.scss'
import img2 from '@/assets/bg/2.png'
import img3 from '@/assets/bg/3.png'
import img4 from '@/assets/bg/4.png'
import img1 from '@/assets/bg/1.png'
import imgl1 from '@/assets/bg/l1.png'
import imgs4 from '@/assets/bg/s4.png'
import imgr1 from '@/assets/bg/r1.png'
import imgr2 from '@/assets/bg/r2.png'
import imgy3 from '@/assets/bg/y3.png'
import imgy2 from '@/assets/bg/y2.png'
import imgy from '@/assets/bg/y.png'
import img5 from '@/assets/bg/5.png'
import imgy1 from '@/assets/bg/y1.png'
import imgs3 from '@/assets/bg/s3.png'
import imgs1 from '@/assets/bg/s1.png'
import imgs2 from '@/assets/bg/s2.png'

const imgShowArray = [
  {
    className: bg.img1,
    src: img2,
    alt: 'img1'
  },
  {
    className: bg.img2,
    src: img3,
    alt: 'img2'
  },
  {
    className: bg.img3,
    src: img4,
    alt: 'img3'
  },
  {
    className: bg.img4,
    src: img1,
    alt: 'img4'
  },
  {
    className: bg.img5,
    src: imgl1,
    alt: 'img5'
  },
  {
    className: bg.img6,
    src: imgs4,
    alt: 'img6'
  },
  {
    className: bg.img7,
    src: imgs4,
    alt: 'img7'
  },
  {
    className: bg.img8,
    src: imgs4,
    alt: 'img8'
  },
  {
    className: bg.img9,
    src: imgr1,
    alt: 'img9'
  },
  {
    className: bg.img10,
    src: imgr2,
    alt: 'img10'
  },
  {
    className: bg.img11,
    src: imgy3,
    alt: 'img11'
  },
  {
    className: bg.img121,
    src: imgy2,
    alt: 'img121'
  },
  {
    className: bg.img12,
    src: imgy,
    alt: 'img12'
  },
  {
    className: bg.img13,
    src: img5,
    alt: 'img13'
  },
  {
    className: bg.img14,
    src: imgy1,
    alt: 'img14'
  },
  {
    className: bg.img15,
    src: imgs3,
    alt: 'img15'
  },
  {
    className: bg.img16,
    src: imgs1,
    alt: 'img16'
  },
  {
    className: bg.img17,
    src: imgs2,
    alt: 'img17'
  }
]
const StaticBgAnimation = () => {
  return (
    <div className={bg.onion_bg}>
      {imgShowArray.map((item, index) => (
        <img
          className={item.className}
          src={item.src}
          alt={item.alt}
          key={index}
          loading="lazy"
        />
      ))}
    </div>
  )
}
export default StaticBgAnimation
