import { get, networkRequest } from '@/utils/network.utils'
import examApi from '@/apis/examApi'
const getSummaryExam = async (id, params) => {
  const [error, result] = await networkRequest(() =>
    get(examApi.summaryExam.replace(':id', id), params)
  )
  if (!error) {
    return result
  }
}
const getAnswerboardExam = async params => {
  const [error, result] = await networkRequest(() =>
    get(examApi.answerboardExam, params)
  )
  if (!error) {
    return result
  }
}
export { getSummaryExam, getAnswerboardExam }
