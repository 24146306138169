export default {
  namespace: 'ui',
  state: {
    privateTips: false,
    homePageLoading: false,
    navInfo: {
      level: null,
      name: ''
    },
    guideHomePageStatus: false,
    navStatus: {
      iconHome: false,
      tipsText: false
    }
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(async ({ pathname }) => {
        try {
        } catch (err) {}
      })
    }
  },
  effects: {
    *privateTips({ payload }, { put }) {
      yield put({ type: 'changePrivateTips', payload })
    }
  },
  reducers: {
    changePrivateTips(state, action) {
      state.privateTips = action.payload.privateTipsShow
      return state
    },
    homePageLoading(state, { loading }) {
      return {
        ...state,
        homePageLoading: loading
      }
    },
    changeNavItems(state, { level, name, path, routerType, routerPath }) {
      return {
        ...state,
        navInfo: {
          ...state.navInfo,
          level,
          name,
          path,
          routerType,
          routerPath
        }
      }
    },
    changeBg(state, { status }) {
      return {
        ...state,
        guideHomePageStatus: status
      }
    },
    changeNavIconAndText(state, { iconHome, tipsText }) {
      return {
        ...state,
        navStatus: {
          ...state.navStatus,
          iconHome,
          tipsText
        }
      }
    }
  }
}
