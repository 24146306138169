/**
 *  @Author PandaCure
 *  @DATE 2020/1/16
 *  @Project tw-website
 */
import React, { memo } from 'react'
import css from './index.module.scss'
import { connect } from 'dva'
import PropTypes from 'prop-types'
import addImage from '@/assets/deliberate-practice/add.png'
import subImage from '@/assets/deliberate-practice/sub.png'
import mulImage from '@/assets/deliberate-practice/mul.png'
import divImage from '@/assets/deliberate-practice/div.png'
import addImage2 from '@/assets/deliberate-practice/addImage_2.png'
import subImage2 from '@/assets/deliberate-practice/subImage_2.png'
import mulImage2 from '@/assets/deliberate-practice/mulImage_2.png'
import divImage2 from '@/assets/deliberate-practice/divImage_2.png'
const convertImage = {
  addStateLevel1: addImage,
  subStateLevel1: subImage,
  mulStateLevel1: mulImage,
  divStateLevel1: divImage,
  addStateLevel2: addImage2,
  subStateLevel2: subImage2,
  mulStateLevel2: mulImage2,
  divStateLevel2: divImage2
}
const initValue = new Array(3).fill(1)
const LeftAnimation = () => {
  return (
    <ul className={css.left}>
      {initValue.map((v, i) => (
        <li className={css.left_item} key={i} />
      ))}
    </ul>
  )
}
const RightAnimation = () => {
  return (
    <ul className={css.right}>
      {initValue.map((v, i) => (
        <li className={css.right_item} key={i} />
      ))}
    </ul>
  )
}
const CountDownComponents = ({ time, type }) => {
  return (
    <div
      className={css.container}
      style={{
        backgroundImage: `url(${convertImage[type]})`
      }}
    >
      <LeftAnimation />
      <RightAnimation />
      <div className={css.rotate} />
      <div className={css.time}>
        {time === 60 ? '01:00' : `00:${time < 10 ? `0${time}` : time}`}
      </div>
    </div>
  )
}
CountDownComponents.propTypes = {
  time: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
}
export default connect()(memo(CountDownComponents))
