import pathToRegexp from 'path-to-regexp'
import _ from 'lodash'
import {
  getStudyDetails,
  getCourseLists,
  getAllGoodsList,
  getPracticeFinishedStatus,
  getLatestLearnRecord,
  getExaminations
} from '@/services/homeServices'
const homePath = '/home/:id'
const finishedStatusPath = '/home/practice/finished'
const goodListPath = '/home/good-list'
export default {
  namespace: 'home',
  state: {
    studyRate: {
      linearTime: 0,
      totalVideo: 0,
      focusRate: 0
    },
    chooseCourse: {
      chapters: []
    },
    finishedStatus: [],
    chooseChapterId: '',
    initRecordChapter: '',
    examinationData: {},
    paid: false
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(function({ pathname }) {
        const home = pathToRegexp(homePath, [], {}).exec(pathname)
        if (home && _.isArray(home) && home.length > 0) {
          dispatch({ type: 'studyRate' })
          dispatch({ type: 'ui/homePageLoading', loading: true })
        }
        const finishedStatus = pathToRegexp(finishedStatusPath, [], {}).exec(
          pathname
        )
        if (
          finishedStatus &&
          _.isArray(finishedStatus) &&
          finishedStatus.length > 0
        ) {
          dispatch({ type: 'finishedStatus' })
        }
        const goodList = pathToRegexp(goodListPath, [], {}).exec(pathname)
        if (goodList && _.isArray(goodList) && goodList.length > 0) {
          dispatch({ type: 'goodLists' })
        }
      })
    }
  },
  effects: {
    *studyRate({ payload }, { call, put }) {
      const result = yield call(getStudyDetails)
      yield put({
        type: 'updateStudyRate',
        result
      })
    },
    *goodLists({ payload }, { call, put }) {
      const from = _.get(payload, 'from', false)
      const courseId = _.get(payload, 'courseId', '')
      const goodsResult = yield call(getAllGoodsList)
      yield put({
        type: 'updateCourseList',
        goodsResult
      })
      if (from === 'home') {
        yield put({
          type: 'handleLatestLearn',
          payload: {
            courseId
          }
        })
      }
    },
    *handleLatestLearn({ payload }, { call, put, select }) {
      // 商品列表
      const courseId = _.get(payload, 'courseId', 0)
      const learnResult = yield call(getLatestLearnRecord, courseId)
      const paidStatus = _.get(learnResult, 'paidStatus', false)
      yield put({
        type: 'updatePaid',
        paid: paidStatus
      })
      const existChapterId = paidStatus
        ? _.get(learnResult, 'chapterId', null)
        : false
      if (!courseId) throw new Error('找不到课程ID')
      yield put({
        type: 'chooseCourseInfo',
        courseId,
        existSectionId: existChapterId
      })
    },
    *chooseCourseInfo({ courseId, existSectionId = false }, { call, put }) {
      // 找出章节
      const courseResult = yield call(getCourseLists, courseId)
      let chapterDetails = _.find(
        courseResult.chapters,
        value => value.id === existSectionId
      )
      if (!chapterDetails) {
        chapterDetails = _.get(courseResult, 'chapters.0')
      }
      yield put({
        type: 'global/chooseChapterDetails',
        value: chapterDetails
      })
      const sectionId = existSectionId || _.get(courseResult, 'chapters.0.id')
      yield put({
        type: 'initChapterInfo',
        chapterId: sectionId
      })
      yield put({
        type: 'global/currentChooseOptInfo',
        courseId
      })
      // 更新reducer
      yield put({
        type: 'updateChooseCourseInfo',
        courseResult
      })
      yield put({
        type: 'chooseSectionId',
        chapterId: sectionId
      })
      yield put({
        type: 'global/getChooseChapterDetails',
        chapterId: sectionId,
        courseId
      })
    },
    *finishedStatus({ payload }, { call, select, put }) {
      const currentChooseCourseInfo = yield select(
        state => state.global.currentChooseOptInfo
      )
      const currentChooseTopic = yield select(
        state => state.global.currentChooseTopic
      )
      const params = {
        courseId: currentChooseCourseInfo.courseId || payload.courseId,
        chapterId: currentChooseCourseInfo.chapterId || payload.chapterId,
        topicId: currentChooseTopic.id || payload.topicId
      }
      const result = yield call(getPracticeFinishedStatus, params)
      yield put({
        type: 'updateFinishedStatus',
        finishedStatus: result
      })
      yield put({
        type: 'global/getChooseChapterDetails',
        courseId: payload.courseId,
        chapterId: payload.chapterId,
        topicId: payload.topicId
      })
    },
    *chooseSectionId({ chapterId }, { put }) {
      yield put({
        type: 'updateChooseChapter',
        chapterId
      })
    },
    *getExaminationData(
      { chapterId, courseId, id, isExist },
      { call, put, select }
    ) {
      let result = {}
      const paid = yield select(state => state.home.paid)
      if (isExist && paid) {
        result = yield call(getExaminations, id, {
          courseId,
          chapterId
        })
      }
      yield put({
        type: 'updateExaminationData',
        examinationData: {
          ...result,
          isExist__examination: isExist
        }
      })
      return result
    }
  },
  reducers: {
    updateStudyRate(state, { result }) {
      return {
        ...state,
        studyRate: {
          ...result
        }
      }
    },
    updateChooseCourseInfo(state, { courseResult }) {
      return {
        ...state,
        chooseCourse: {
          ...courseResult
        }
      }
    },
    updateCourseList(state, { goodsResult }) {
      return {
        ...state,
        courseList: goodsResult
      }
    },
    updateChooseTopic(state, { topicInfo }) {
      return {
        ...state,
        currentChooseTopic: {
          ...topicInfo
        }
      }
    },
    updateFinishedStatus(state, { finishedStatus }) {
      return {
        ...state,
        finishedStatus
      }
    },
    updateChooseChapter(state, { chapterId }) {
      return {
        ...state,
        chooseChapterId: chapterId
      }
    },
    initChapterInfo(state, { chapterId }) {
      return {
        ...state,
        initRecordChapter: chapterId
      }
    },
    updateExaminationData(state, { examinationData }) {
      return {
        ...state,
        examinationData
      }
    },
    updatePaid(state, { paid }) {
      return {
        ...state,
        paid
      }
    }
  }
}
