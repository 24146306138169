import { networkUrl } from '@/utils/network.utils'
import domains from '@/utils/networkConfig'
const APIs = {
  websiteUrl: {
    getStudyDetails: '/records/summary',
    getCourseLists: '/study/courses/:id',
    getSectionInfo: '/study/chapters/:id',
    getAllGoodsList: '/products',
    getVideoDetails: '/study/videos/:id',
    getPracticeDetails: '/study/questions/:id',
    getPracticeFinishedStatus: '/records/answerboard',
    getResourceSign: '/utils/signedUrl',
    getLatestLearnRecord: '/records/latest',
    getUserInfo: '/users/me',
    login: '/login',
    checkBills: '/bills/:id/check',
    logout: '/logout',
    getExaminations: '/study/exams/:id',
    clearQuestions: '/records/clearQuestions',
    bindLine: '/users/line/bind',
    getUnbindLineCode: '/users/line/sendToken',
    unbindLine: '/users/line/unbind',
    getCurrentLearnCourses: '/courses', // 登录首页 课程列表
    getSummaryWeeks: '/records/summaryWeeks',
    getSummaryRange: '/records/summaryRange',
    getTimeline: '/timeline',
    todayNotification: '/notifications/today'
  }
}
export default networkUrl(APIs, domains)
