import dva from 'dva'
import createLoading from 'dva-loading'
import { registerModels } from './modals'
import Main from '@/screens/Main/Main'
import Phone from '@/mobile/MobilePage'
import { isWindowPC } from '@/utils/tool.utils'
const onError = err => {
  console.error('请求失败:', err)
}
const app = dva({
  onError,
  onStateChange: () => {}
})
app.use(createLoading({}))
// app.use(testPlugins({}))
if (!global.registered) {
  registerModels(app)
}
global.registered = true
app.router(isWindowPC() ? Main : Phone)
app.start('#root')
