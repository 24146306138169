/**
 *  @Author PandaCure
 *  @DATE 2020/1/16
 *  @Project tw-website
 */
import React, { memo, useMemo } from 'react'
import css from './index.module.scss'
import { connect, useSelector } from 'dva'
import PropTypes from 'prop-types'

const DiffcultLevelOne = ({ personals, currentRecordScore, type }) => {
  const rank = useSelector(state => state.dp.rank)
  const listRank = useMemo(() => {
    const personals = rank.get(type) || []
    return [...personals].splice(3)
  }, [rank, type])
  return (
    <>
      <ul className={css.rate}>
        <li className={css.show_rate}>
          <div className={css.rate_score}>
            {personals[1] ? personals[1].score : <div className={css.none} />}
          </div>
          <div className={css.rate_text}>記錄得分</div>
          <div className={css.rate_name}>
            {personals[1] ? personals[1].nickname : '暫無'}
          </div>
        </li>
        <li className={css.show_rate}>
          <div className={css.rate_score} style={{ marginTop: '13px' }}>
            {personals[0] ? personals[0].score : <div className={css.none} />}
          </div>
          <div className={css.rate_text}>記錄得分</div>
          <div className={css.rate_name}>
            {personals[0] ? personals[0].nickname : '暫無'}
          </div>
        </li>
        <li className={css.show_rate}>
          <div className={css.rate_score}>
            {personals[2] ? personals[2].score : <div className={css.none} />}
          </div>
          <div className={css.rate_text}>記錄得分</div>
          <div className={css.rate_name}>
            {personals[2] ? personals[2].nickname : '暫無'}
          </div>
        </li>
      </ul>
      <ul className={css.scroll}>
        {listRank && listRank.length > 0 ? (
          listRank.map((v, k) => {
            return (
              <li className={css.s_item} key={k}>
                <div className={css.s_rate}>
                  <span className={css.s_a}>{k + 4}</span>
                  <span className={css.s_b}>{v.nickname}</span>
                </div>
                <div className={css.s_c}>{v.score}</div>
              </li>
            )
          })
        ) : (
          <div />
        )}
      </ul>
      {currentRecordScore && (
        <div className={css.bottom1}>
          <div className={css.b}>
            <div className={css.h} />
            <div className={css.d}>
              <div className={css.bbb}>
                <span
                  className={css.ab}
                  style={{
                    visibility: currentRecordScore.participate
                      ? 'visible'
                      : 'hidden'
                  }}
                >
                  {currentRecordScore.rank}
                </span>
                <span className={css.ac}>{currentRecordScore.nickname}</span>
              </div>
              <div className={css.bb}>
                {currentRecordScore.participate
                  ? currentRecordScore.score
                  : '暫無'}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
DiffcultLevelOne.propTypes = {
  personals: PropTypes.array.isRequired,
  currentRecordScore: PropTypes.shape({
    score: PropTypes.number.isRequired,
    nickname: PropTypes.string.isRequired
  }).isRequired
}
export default connect()(memo(DiffcultLevelOne))
