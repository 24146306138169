/**
 *  @Author PandaCure
 *  @DATE 2020/2/12
 *  @Project tw-website
 */
import React, { Suspense } from 'react'
import { routerRedux } from 'dva'
import RouteWithSubRoutes from '@/components/RouteWithSubRoutes'
import routes from './router'
const { ConnectedRouter } = routerRedux
const MobilePage = ({ app, history }) => {
  return (
    <Suspense fallback={null}>
      <ConnectedRouter history={history}>
        <RouteWithSubRoutes routes={routes} history={history} app={app} />
      </ConnectedRouter>
    </Suspense>
  )
}
MobilePage.propTypes = {}
export default MobilePage
