import { networkUrl } from '@/utils/network.utils'
import domains from '@/utils/networkConfig'
const APIs = {
  websiteUrl: {
    faq: '/qas',
    instructionTags: '/compares/tags',
    instructionGroupDetails: '/compares/groups/:id',
    instructionGroupLists: '/compares/groups',
    instructionContext: '/compares/items',
    checkCouponInterface: '/bills/check/coupon',
    stayPageTime: '/logs',
    convertTextToAudio: '/utils/textToAudio'
  }
}
export default networkUrl(APIs, domains)
