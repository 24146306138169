import axios from 'axios'
import { message } from 'antd'
import _ from 'lodash'
const instance = axios.create({
  timeout: 2000 * 10,
  withCredentials: true
})
instance.interceptors.request.use(
  config => {
    if (!navigator.onLine) {
      message.error('當前網絡不佳，請檢查網絡后重試', 2)
      return false
    }
    return config
  },
  error => Promise.reject(new Error(error))
)
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      const originUrl = window.location.origin
      window.location.replace(`${originUrl}/#/login`)
    } else {
      if (error.code === 'ECONNABORTED') {
        // TODO 网络超时处理
      }
      process.env.NODE_ENV !== 'development' &&
        window.predem.sendEvent('網絡響應失敗', {
          msg: error.message,
          status: error.response.status,
          statusText: error.response.statusText,
          code: error.code,
          error: error,
          config: error.config,
          response: {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers
          },
          request: error.request
        })
      return Promise.reject(error)
    }
  }
)
const get = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        ...config,
        params: {
          ...params
        }
      })
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}
const post = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, config)
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}
const put = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params, config)
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}
const remove = (url, params, config) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        ...config,
        params: {
          ...params
        }
      })
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}
const addDomain = domain => path => domain + path
const networkUrl = (apis, domains) => {
  return _.reduce(
    _.keys(apis),
    (init, value) => {
      const url = apis[value]
      _.each(url, (path, key) => {
        if (!init[key]) {
          init[key] = addDomain(domains[value])(path)
        } else {
          console.warn(`已存在此域名[${url}]下的资源路径[${path}]`)
        }
      })
      return init
    },
    {}
  )
}
const networkRequest = async fn => {
  const error = null
  try {
    const reqData = await fn()
    let result = _.get(reqData, 'data.data', null)
    // 兼容处理
    if (!result || (_.isObject(result) && _.keys(result).length === 0)) {
      if (_.keys(_.get(reqData, 'data', null)).length >= 3) {
        result = _.get(reqData, 'data', null)
      }
    }
    return [error, result]
  } catch (error) {
    return [error, null]
  }
}
export { get, post, put, remove, networkUrl, networkRequest }
