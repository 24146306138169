import React, { useEffect, memo } from 'react'
import { connect } from 'dva'
const GuidePage = props => {
  const { userInfo } = props
  useEffect(() => {
    const search = window.location.hash.slice(3)
    const urlParams = new URLSearchParams(search)
    const pid = urlParams.get('order')
    const token = urlParams.get('code') || urlParams.get('token')
    if (token && !window.localStorage.getItem('callback')) {
      window.localStorage.setItem('callback', 'success')
    }
    userInfo(pid, token).then(() => {
      window.sessionStorage.removeItem('about.from')
    })
  }, [userInfo])
  return <div />
}
const mapDispatchToProps = dispatch => {
  return {
    userInfo: (pid, token) =>
      dispatch({
        type: 'common/userInfo',
        pid,
        token
      })
  }
}
export default connect(
  null,
  mapDispatchToProps
)(memo(GuidePage))
