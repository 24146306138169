/**
 *  @Author PandaCure
 *  @DATE 2020/4/20
 *  @Project tw-website
 */
import React, { lazy, memo } from 'react'
import css from './index.module.scss'
import { connect } from 'dva'
import PropTypes from 'prop-types'
import cs from 'classnames'
import _ from 'lodash'
import titleImage from '@/assets/deliberate-practice/modal_sign_title.png'
import warnImage from '@/assets/deliberate-practice/modal_sign_warn.png'
import groupImage from '@/assets/deliberate-practice/modal_sign_group.png'
import introImage from '@/assets/deliberate-practice/modal_sign_intro.png'
import bonusImage from '@/assets/deliberate-practice/modal_sign_jianpin.png'
import methodImage from '@/assets/deliberate-practice/modal_sign_method.png'
const OnionModal = lazy(() => import('@/components/OnionModal/OnionModal'))
const SignUpModal = ({
  signUpVisible,
  setSignUpVisible,
  activityInfo,
  handleSignUp,
  isSignUp
}) => {
  return (
    <OnionModal visible={signUpVisible} type={'free'}>
      <div className={css.model}>
        <div className={css.close} onClick={() => setSignUpVisible(false)} />
        <img src={titleImage} alt="titleImage" className={css.title} />
        <div>
          <ul className={css.intro_block}>
            <li className={cs(css.item, css.left)}>
              <div className={css.item_block}>
                <img src={introImage} alt="introImage" />
                {activityInfo.description}
              </div>
              <div className={css.item_block}>
                <img src={methodImage} alt="methodImage" />
                {_.map(activityInfo.path, (v, i) => {
                  return (
                    <div key={i} className={css.text_intro}>
                      {v}
                    </div>
                  )
                })}
              </div>
              <div className={css.item_block}>
                <img src={groupImage} alt="groupImage" className={css.group} />
                {_.map(activityInfo.content, (v, i) => {
                  return (
                    <div key={i} className={css.text_intro}>
                      {v.description}
                    </div>
                  )
                })}
              </div>
            </li>
            <li className={cs(css.item, css.right)}>
              <div className={css.hack}>
                <div className={css.item_block}>
                  <img src={bonusImage} alt="bonusImage" />
                  {_.get(activityInfo, 'award.description', '')}
                  <img
                    src={_.get(activityInfo, 'award.image', '')}
                    alt="intro"
                    className={css.bouns}
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className={css.show}>
            <div className={css.num_block}>
              共有
              <span className={css.num}>
                {_.get(activityInfo, 'total', 1000)}
              </span>
              名用戶正在挑戰爭霸賽活動
            </div>
            <button className={css.button_block} onClick={handleSignUp}>
              <img src={warnImage} alt="warnImage" />
              {isSignUp ? '已報名' : '立即報名'}
            </button>
          </div>
        </div>
      </div>
    </OnionModal>
  )
}
SignUpModal.propTypes = {
  signUpVisible: PropTypes.bool.isRequired,
  isSignUp: PropTypes.bool.isRequired,
  setSignUpVisible: PropTypes.func.isRequired,
  handleSignUp: PropTypes.func.isRequired,
  activityInfo: PropTypes.object.isRequired
}
export default connect()(memo(SignUpModal))
