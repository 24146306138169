import homeApi from '@/apis/homeApi'
import { get, networkRequest, post, remove } from '@/utils/network.utils'
const getStudyDetails = async () => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getStudyDetails)
  )
  if (!error) {
    return result
  }
}
const getCourseLists = async id => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getCourseLists.replace(':id', id))
  )
  if (!error) {
    return result
  }
}
const getSectionInfo = async (sectionId, params) => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getSectionInfo.replace(':id', sectionId), params)
  )
  if (!error) {
    return result
  }
}
const getAllGoodsList = async () => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getAllGoodsList, { page: 1, count: 50 })
  )
  if (!error) {
    return result
  }
}
const getVideoDetails = async (videoId, params) => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getVideoDetails.replace(':id', videoId), params)
  )
  if (!error) {
    return result
  }
}
const getPracticeDetails = async (practiceId, params) => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getPracticeDetails.replace(':id', practiceId), params)
  )
  if (!error) {
    return result
  }
}
const getPracticeFinishedStatus = async params => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getPracticeFinishedStatus, params)
  )
  if (!error) {
    return result
  }
}
const getResourceSign = async params => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getResourceSign, params)
  )
  if (!error) {
    return result
  }
}
const getLatestLearnRecord = async courseId => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getLatestLearnRecord, courseId ? { courseId } : {})
  )
  if (!error) {
    return result
  }
}
const getUserInfo = async () => {
  const [error, result] = await networkRequest(() => get(homeApi.getUserInfo))
  if (!error) {
    return result
  }
}
const login = async params => {
  const [error, result] = await networkRequest(() =>
    post(homeApi.login, params)
  )
  if (!error) {
    return result
  }
}
const checkBills = async id => {
  const [error, result] = await networkRequest(() =>
    post(homeApi.checkBills.replace(':id', id))
  )
  if (!error) {
    return result
  }
}
const logout = async () => {
  const [error, result] = await networkRequest(() => remove(homeApi.logout))
  if (!error) {
    return result
  }
}
const getExaminations = async (id, params) => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getExaminations.replace(':id', id), params)
  )
  if (!error) {
    return result
  }
}
const clearQuestions = async params => {
  const [error, result] = await networkRequest(() =>
    post(homeApi.clearQuestions, params)
  )
  if (!error) {
    return result
  }
}
const getCurrentLearnCourses = async () => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getCurrentLearnCourses)
  )
  if (!error) {
    return result
  }
}
const createBindLine = async params => {
  const [error, result] = await networkRequest(() =>
    post(homeApi.bindLine, params)
  )
  if (!error) {
    return result
  } else {
    return Promise.reject(error)
  }
}
const getUnbindLineCode = async params => {
  const [error, result] = await networkRequest(() =>
    post(homeApi.getUnbindLineCode, params)
  )
  if (!error) {
    return result
  } else {
    return Promise.reject(error)
  }
}
const unbindLine = async params => {
  const [error, result] = await networkRequest(() =>
    post(homeApi.unbindLine, params)
  )
  if (!error) {
    return result
  } else {
    return Promise.reject(error)
  }
}
const getSummaryWeeks = async params => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getSummaryWeeks, { ...params })
  )
  if (!error) {
    return result
  }
}
const getSummaryRange = async params => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getSummaryRange, params)
  )
  if (!error) {
    return result
  }
}
const getTimeline = async params => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.getTimeline, params)
  )
  if (!error) {
    return result
  }
}

const todayNotification = async params => {
  const [error, result] = await networkRequest(() =>
    get(homeApi.todayNotification, params)
  )
  if (!error) {
    return result
  }
}

export {
  getStudyDetails,
  getCourseLists,
  getSectionInfo,
  getAllGoodsList,
  getVideoDetails,
  getPracticeDetails,
  getPracticeFinishedStatus,
  getResourceSign,
  getLatestLearnRecord,
  getUserInfo,
  login,
  checkBills,
  logout,
  getExaminations,
  clearQuestions,
  getCurrentLearnCourses,
  createBindLine,
  unbindLine,
  getUnbindLineCode,
  getSummaryWeeks,
  getSummaryRange,
  getTimeline,
  todayNotification
}
