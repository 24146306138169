/**
 *  @Author PandaCure
 *  @DATE 2019/12/31
 *  @Project tw-website
 */
import React, { createContext, memo, useEffect, useState } from 'react'
import { connect } from 'dva'
import PropTypes from 'prop-types'
import RouteWithSubRoutes from '@/components/RouteWithSubRoutes'
import { routerDeliberatePracticePath } from '@/router'
import Loading from '@/components/Loading.common'
import { isIPadAndroid } from '@/utils/tool.utils'
import practiceCss from '@/screens/OldPracticePage/practice.module.scss'
import verticalScreenImage from '@/assets/status/verticalScreen.gif'
export const VerticalScreenContext = createContext(false)
const DeliberatePractice = props => {
  const [loading, setLoading] = useState(true)
  const [verticalScreen, setVerticalScreen] = useState(false)

  // TODO  有一个warning没找到
  useEffect(() => {
    const images = require.context(
      '../../assets/deliberate-practice',
      false,
      /\.(png|svg)$/
    )
    let i = 0
    images.keys().forEach(value => {
      const image = new Image()
      image.onload = () => {
        if (++i === images.keys().length) setLoading(false)
      }
      image.src = images(value)
    })
  }, [])
  useEffect(() => {
    if (isIPadAndroid()) {
      const events =
        'onorientationchange' in window ? 'orientationchange' : 'resize'
      const handleEvents = () => {
        new Promise(() => {
          return setTimeout(() => {
            const width = document.documentElement.clientWidth
            const height = document.documentElement.clientHeight
            setVerticalScreen(width < height)
          }, 500)
        })
          .then(data => {
            data = null
          })
          .catch(err => err)
      }
      handleEvents()
      window.addEventListener(events, handleEvents, false)
      return () => window.removeEventListener(events, handleEvents, false)
    }
  }, [setVerticalScreen])
  return (
    <VerticalScreenContext.Provider value={verticalScreen}>
      <div
        style={{
          background: `linear-gradient(180deg,rgba(13,8,38,1) 0%,rgba(0,33,79,1) 100%)`,
          width: '100vw',
          height: '100vh'
        }}
      >
        {loading ? (
          <Loading show />
        ) : (
          <>
            {verticalScreen && (
              <div className={practiceCss.modal}>
                <img
                  src={verticalScreenImage}
                  alt="請轉動屏幕，在横屏模式下使用"
                  className={practiceCss.img}
                />
                <p className={practiceCss.explain}>
                  請轉動屏幕，在横屏模式下使用
                </p>
              </div>
            )}
            <RouteWithSubRoutes
              routes={routerDeliberatePracticePath(props.app)}
            />
          </>
        )}
      </div>
    </VerticalScreenContext.Provider>
  )
}
DeliberatePractice.propTypes = {
  history: PropTypes.object
}
export default connect()(memo(DeliberatePractice))
