/**
 *  @Author PandaCure
 *  @DATE 2020/4/20
 *  @Project tw-website
 */
import React, { lazy, memo } from 'react'
import css from './index.module.scss'
import { connect } from 'dva'
import PropTypes from 'prop-types'
import cs from 'classnames'
import titleImage from '@/assets/deliberate-practice/modal_rank_title.png'
import simpleImage from '@/assets/deliberate-practice/modal_rank_group_simple.png'
import diffcultImage from '@/assets/deliberate-practice/modal_rank_d_group.png'
import facebookImage from '@/assets/deliberate-practice/facebook_share.png'
import _ from 'lodash'
const OnionModal = lazy(() => import('@/components/OnionModal/OnionModal'))
const RankListComponent = ({
  rankListVisible,
  setRankListVisible,
  modalRankData,
  type,
  sharedFacebook,
  activityInfo
}) => {
  // TODO 暫時這麼寫吧 可以用循環 但是UI title 不足
  const firstGroup = modalRankData.get(`${type}_1`)
  const secondGroup = modalRankData.get(`${type}_2`)
  const level = _.get(activityInfo, 'content.0.level', [])
  return (
    <OnionModal visible={rankListVisible} type={'free'}>
      <div className={css.model}>
        <div className={css.close} onClick={() => setRankListVisible(false)} />
        <img src={titleImage} alt="titleImage" className={css.title} />
        <div className={css.rank_block}>
          {~level.indexOf('level1') && (
            <div className={cs(css.rank, css.rank_0)}>
              <img src={simpleImage} alt="simpleImage" />
              <ul className={css.list}>
                {_.map(firstGroup, (v, k) => {
                  return (
                    <li className={css.list_item} key={k}>
                      <div className={css.info}>
                        <div className={css.rank_num}>{k + 1}</div>
                        {v.nickname}
                      </div>
                      <div className={css.score}>{v.score}</div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {~level.indexOf('level2') && (
            <div className={cs(css.rank, css.rank_1)}>
              <img src={diffcultImage} alt="diffcultImage" />
              <ul className={css.list}>
                {_.map(secondGroup, (v, k) => {
                  return (
                    <li className={css.list_item} key={k}>
                      <div className={css.info}>
                        <div className={css.rank_num}>{k + 1}</div>
                        {v.nickname}
                      </div>
                      <div className={css.score}>{v.score}</div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
        <div className={cs(css.fb)} onClick={sharedFacebook}>
          <img src={facebookImage} alt="facebookImage" />
        </div>
      </div>
    </OnionModal>
  )
}
RankListComponent.propTypes = {
  rankListVisible: PropTypes.bool.isRequired,
  setRankListVisible: PropTypes.func.isRequired
}
export default connect()(memo(RankListComponent))
