/**
 *  @Author PandaCure
 *  @DATE 2020/1/6
 *  @Project tw-website
 */
import React, { memo } from 'react'
import css from './index.module.scss'
import { connect, routerRedux, useDispatch } from 'dva'
import PropTypes from 'prop-types'
import btnImage from '@/assets/deliberate-practice/button.png'
import backImage from '@/assets/deliberate-practice/back.png'
import classNames from 'classnames'
const InsertButton = ({ children, cls }) => {
  return (
    <div className={classNames(css.insert, cls)}>
      <div className={css.insert_1}>
        <img src={btnImage} alt="btnImage" />
      </div>
      {children}
      <div className={css.insert_2}>
        <img src={btnImage} alt="btnImage" />
      </div>
    </div>
  )
}
const NavComponent = ({ children, position, backRouter }) => {
  const dispatch = useDispatch()
  const navBack = () => {
    if (backRouter) {
      backRouter()
    } else {
      dispatch(routerRedux.goBack())
    }
  }
  return (
    <div className={css.dp_nav}>
      <div className={css.back} onClick={() => navBack()}>
        <div className={css.btn}>
          <img src={btnImage} alt="btnImage" />
        </div>
        <div className={css.back}>
          <img src={backImage} alt="backImage" />
        </div>
      </div>
      <div className={css.nav} style={{ justifyContent: position }}>
        <div className={css.btn}>
          <img src={btnImage} alt="btnImage" />
        </div>
        {children}
      </div>
      <div className={css.logo}>
        <div className={css.btn}>
          <img src={btnImage} alt="btnImage" />
        </div>
        <InsertButton cls={css.empty} />
      </div>
    </div>
  )
}
NavComponent.propTypes = {
  position: PropTypes.oneOf(['center', 'flex-start', 'flex-end']).isRequired
}
export default connect()(memo(NavComponent))
export { InsertButton }
