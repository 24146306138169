import { logoutAction } from '@/utils/logout.utils'
import { connect, router } from 'dva'
import React, { lazy, Suspense, useEffect } from 'react'
import './AnimationRouterContainer/animation.css'
const NoMatch = lazy(() => import('@/screens/NotFoundPage'))
const { Route, withRouter, Switch } = router
const MiddleComponent = () => {
  return (
    <Suspense fallback={null}>
      <NoMatch />
    </Suspense>
  )
}
const NoFound = () => <Route component={MiddleComponent} />
const RedirectComponent = () => {
  useEffect(() => {
    console.log('从 RouteWithSubRoutes.js -> RedirectComponent 中登出')
    logoutAction()
  }, [])
  return null
}
const RouteWithSubRoutes = props => {
  const $app = props.app
  const { routes, location } = props
  const redirect = location.pathname === '/home'
  const configRoute = routes.map((route, i) => {
    return (
      <Route
        path={route.path}
        render={value => (
          <route.component
            {...value}
            routes={routes}
            app={$app}
            argument={props}
          />
        )}
        key={i}
        exact={route.exact}
      />
    )
  })
  return (
    <React.Fragment>
      {redirect ? (
        <RedirectComponent />
      ) : (
        <Switch>
          {configRoute}
          <NoFound />
        </Switch>
      )}
    </React.Fragment>
  )
}
const mapStateToProp = state => ({})
export default withRouter(connect(mapStateToProp)(RouteWithSubRoutes))
