import React from 'react'

class Loading extends React.Component {
  render() {
    const { show, inline } = this.props
    return (
      <div
        className={
          show && inline
            ? 'inline-loading loading'
            : show
            ? 'loading'
            : 'loading hidden'
        }
      >
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    )
  }
}
export default Loading
