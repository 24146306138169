import getRouterData from '@/router/getRouterData'
import _ from 'lodash'
const isSafari =
  typeof navigator !== 'undefined' &&
  /Version\/[\d\\.]+.*Safari/.test(navigator.userAgent)
const isIOS =
  typeof navigator !== 'undefined' && navigator.userAgent.match(/ipad|iphone/i)
const isIPad =
  typeof navigator !== 'undefined' && navigator.userAgent.match(/ipad/i)
const is360 =
  typeof navigator !== 'undefined' &&
  (navigator.userAgent.indexOf('QihooBrowser') > -1 ||
    navigator.userAgent.indexOf('QHBrowser') > -1)
const is360EE =
  typeof navigator !== 'undefined' && navigator.userAgent.indexOf('360EE') > -1
const is360SE =
  typeof navigator !== 'undefined' && navigator.userAgent.indexOf('360SE') > -1
const isIE =
  typeof navigator !== 'undefined' &&
  (navigator.userAgent.indexOf('MSIE') > -1 ||
    navigator.userAgent.indexOf('Trident') > -1)
const isEdge =
  typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Edge') > -1
const isFirefox =
  typeof navigator !== 'undefined' &&
  (navigator.userAgent.indexOf('Firefox') > -1 ||
    navigator.userAgent.indexOf('FxiOS') > -1)
const isFirefoxFocus =
  typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Focus') > -1

// 顯示引導用戶使用Chrome的彈窗
const isShowBoswerModal = () =>
  is360 ||
  is360EE ||
  is360SE ||
  isIE ||
  isEdge ||
  isFirefox ||
  isFirefox ||
  isFirefoxFocus

const os = function() {
  const ua = navigator.userAgent
  const isWindowsPhone = /(?:Windows Phone)/.test(ua)
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
  const isAndroid = /(?:Android)/.test(ua)
  const isHuaWei = /HuaweiBrowser/.test(ua)
  const isFireFox = /(?:Firefox)/.test(ua)
  const isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isAndroid && isHuaWei) ||
    (isFireFox && /(?:Tablet)/.test(ua))
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet
  const isPc = !isPhone && !isAndroid && !isSymbian
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc || isTablet || isIPad
  }
}
const isWindowPC = () => window.screen.width >= 534
const isIPadAndroid = () =>
  window.screen.width >= 534 && window.screen.width <= 1024
const computerTime = (time, min = '分', sec = '秒') => {
  const m = time / 60 > 0 ? Math.floor(time / 60) : 0
  const s = Math.floor(time - m * 60)
  return `${m}${min}${s}${sec}`
}
const typePractice = {
  single_choice: '單選題',
  multi_blank: '填空題',
  hybrid: '選擇+填空題',
  exam: '大题'
}
const hh = 60 * 60 * 10
const oneMin = 60
const computerTotalTime = num => {
  if (!_.isNumber(num)) return [0, '分鐘']
  let time, unit
  if (num >= hh) {
    unit = '小時'
    time = (num / hh).toFixed(1)
  } else {
    unit = '分鐘'
    time = (num / oneMin).toFixed(1)
  }
  return [time, unit]
}
export {
  isSafari,
  isIOS,
  is360,
  is360EE,
  is360SE,
  isIE,
  isEdge,
  isFirefox,
  isFirefoxFocus,
  isShowBoswerModal,
  os,
  isIPad,
  computerTime,
  typePractice,
  computerTotalTime,
  isWindowPC,
  isIPadAndroid,
  getRouterData
}
