import * as route from './index'
const getRouteData = () => {
  return [
    ...route.default(null),
    ...route.routerDeliberatePracticePath(null),
    ...route.routerHomePath(null),
    ...route.routerPracticePath(null)
  ].map(value => ({ path: value.path, name: value.name }))
}
export default getRouteData
