import globalModal from './global.modal'
import uiModal from './ui.modal'
import homeModal from './home.modal'
import videoModal from './video.modal'
import practiceModal from './practice.modal'
import commonModal from './common.modal'
import examination from './examination.modal'
import dp from './dp.modal'
// TODO: 可以优化 有优雅写法  require.context exclude=>排除懒加载
export const registerModels = app => {
  app.model(globalModal)
  app.model(uiModal)
  app.model(homeModal)
  app.model(videoModal)
  app.model(practiceModal)
  app.model(commonModal)
  app.model(examination)
  app.model(dp)
}
