/**
 *  @Author PandaCure
 *  @DATE 2019/12/31
 *  @Project tw-website
 */
import React, {
  lazy,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import css from './index.module.scss'
import { connect, useDispatch, routerRedux, useSelector } from 'dva'
// import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import * as additionData from '@/animate-assets/deliberate-practice/addition'
import * as subtractionData from '@/animate-assets/deliberate-practice/subtraction'
import * as multiplicationData from '@/animate-assets/deliberate-practice/multiplication'
import * as divisionData from '@/animate-assets/deliberate-practice/division'
import jia from '@/assets/deliberate-practice/jia.png'
import jian from '@/assets/deliberate-practice/jian.png'
import cheng from '@/assets/deliberate-practice/cheng.png'
import chu from '@/assets/deliberate-practice/chu.png'
import jiaText from '@/assets/deliberate-practice/jia_text.png'
import jianText from '@/assets/deliberate-practice/jian_text.png'
import chengText from '@/assets/deliberate-practice/cheng_text.png'
import chuText from '@/assets/deliberate-practice/chu_text.png'
import jiaIcon from '@/assets/deliberate-practice/jia_icon.png'
import jianIcon from '@/assets/deliberate-practice/jian_icon.png'
import chengIcon from '@/assets/deliberate-practice/cheng_icon.png'
import chuIcon from '@/assets/deliberate-practice/chu_icon.png'
import tool from '@/assets/deliberate-practice/tool.png'
import gos from '@/assets/deliberate-practice/gos.png'
import ddd from '@/assets/deliberate-practice/dddddd.png'
import QuadrangleTab from './components/QuadrangleTab/QuadrangleTab'
import classNames from 'classnames'
import NavComponent, {
  InsertButton
} from './components/NavComponent/NavComponent'
import _ from 'lodash'
import buryPoint from '@/utils/buryPoint.utils'
import noRank from '@/assets/deliberate-practice/no-rank.png'
import DiffcultLevelOne from './components/DiffcultLevelOne/DiffcultLevelOne'
import { VerticalScreenContext } from '../DeliberatePractice/DeliberatePractice'
import ActivityIntroComponent from './components/ActivityIntroComponent/ActivityIntroComponent'
import tagIcon from '@/assets/deliberate-practice/tag_icon.png'
import SignUpModal from './components/SignUpModal/SignUpModal'
import SignUpSuccess from './components/SignUpSuccess/SignUpSuccess'
import RankListComponent from './components/RankListComponent/RankListComponent'
const OnionModal = lazy(() => import('@/components/OnionModal/OnionModal'))

const detailPath = '/deliberate-practice/detail'
const modalConfig = {
  addState: {
    icon: jiaIcon,
    title: jiaText
  },
  subState: {
    icon: jianIcon,
    title: jianText
  },
  mulState: {
    icon: chengIcon,
    title: chengText
  },
  divState: {
    icon: chuIcon,
    title: chuText
  }
}
const buryPointConfig = {
  addState: 'Provingdetails_add_card_click',
  subState: 'Provingdetails_subtraction_card_click',
  mulState: 'Provingdetails_multiplication_card_click',
  divState: 'Provingdetails_division_card_click'
}
const style = {
  position: 'absolute',
  left: 0,
  right: 0,
  width: '100vw',
  height: '100vh'
}
const localToNext = {
  addState: 'plus',
  subState: 'subtract',
  mulState: 'multi',
  divState: 'division'
}
const localToMethod = {
  addState: '加法',
  subState: '減法',
  mulState: '乘法',
  divState: '除法'
}
const currentOpenLevel = ['level1', 'level2']
const DeliberatePracticeEntry = () => {
  const [visible, setVisible] = useState(false)
  const [chooseTab, setChooseTab] = useState(false)
  const [diffcultLevel, setDiffcultLevel] = useState(0)
  const [modalShowConfig, setModalShowConfig] = useState({})
  const [routerName, setRouterName] = useState('')
  const [changeValue, setChangeValue] = useState({ key: -1 })
  const [times] = useState(new Date())
  // 当没有活动时modal里面的排行榜
  const rank = useSelector(state => state.dp.rank)
  const currentRecordScore = useSelector(state => state.dp.currentRecordScore)
  const dispatch = useDispatch()
  const isVerticalScreen = useContext(VerticalScreenContext)
  const defaultOptions = {
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const isSignUp = useSelector(state => state.dp.signUpStatus)
  const activityInfo = useSelector(state => state.dp.activityInfo)
  const currentUserRank = useSelector(state => state.dp.currentUserRank)
  const currentActivityUserRank = useSelector(
    state => state.dp.currentActivityUserRank
  )
  const modalRankData = useSelector(state => state.dp.modalRankData)
  useEffect(() => {
    dispatch({
      type: 'dp/getActivityDetails'
    })
  }, [dispatch])
  const handleEntryModal = item => {
    setChangeValue({ key: 0 })
    setChooseTab(0)
    setModalShowConfig(modalConfig[item])
    setVisible(true)
    setRouterName(item)
    // 模态框入口数据
    dispatch({
      type: 'dp/getCurrentPeopleScore',
      types: localToNext[item],
      level: currentOpenLevel
    })
    dispatch({
      type: 'dp/handleMoreGroupScore',
      types: localToNext[item],
      level: currentOpenLevel
    })
  }
  const handleModalRoute = index => {
    buryPoint({
      category: buryPointConfig[routerName],
      action: 'Provingdetails',
      label: buryPointConfig[routerName]
    })
    Promise.resolve(setVisible(false)).then(() =>
      dispatch(routerRedux.push(detailPath + `/${routerName}${index}`))
    )
  }

  const handleChangeRank = key => {
    setChooseTab(key)
    if (key) {
      dispatch({
        type: 'dp/getRank',
        types: localToNext[routerName],
        level: currentOpenLevel
      })
      buryPoint({
        category: `Provingdetails_rankingtab_click`,
        action: 'Challengedetails',
        label: 'Provingdetails_rankingtab_click'
      })
    }
  }
  useEffect(() => {
    if (!visible) setDiffcultLevel(0)
  }, [visible])
  const handleChangeTab = (index, value) => {
    setDiffcultLevel(index)
    buryPoint({
      category: `Provingdetails_Checkpoint${index}_click`,
      label: `Provingdetails_Checkpoint${index}_click`,
      action: 'Challengedetails'
    })
  }
  useEffect(() => {
    if (window.localStorage.getItem('dp_entry')) {
      window.localStorage.removeItem('dp_entry')
    }
    return () => {
      window.localStorage.setItem('dp_entry', new Date() - times + '')
    }
  })
  const handleBackRouter = () => {
    const time = window.localStorage.getItem('dp_detail') || 0
    buryPoint({
      category: `deliberate_practice_stay_time`,
      label: `deliberate_practice_stay_time`,
      action: 'page',
      value: parseInt(time, 10) + (new Date() - times)
    })
    dispatch(routerRedux.goBack())
  }
  const [signUpVisible, setSignUpVisible] = useState(false)
  const [signUpSuccessVisible, setSignUpSuccessVisible] = useState(false)
  const [rankListVisible, setRankListVisible] = useState(false)
  const handleSignUp = () => {
    if (isSignUp) return false
    dispatch({
      type: 'dp/signUpActivity',
      id: _.get(activityInfo, 'id', '')
    }).then(() => {
      setSignUpVisible(false)
      setSignUpSuccessVisible(true)
    })
  }
  const handleRankModal = (types, level) => {
    dispatch({
      type: 'dp/handleMoreGroupRank',
      types,
      level,
      cid: _.get(activityInfo, 'id', '')
    }).then(() => [setRankListVisible(true)])
  }
  // 获取活动是否开启
  const activityStatus = useSelector(state => state.dp.deliberateStatus)
  // 处理入口模态框红色横幅显示
  const [modalRedTips, setModalRedTips] = useState(false)
  const currentOpenDeliberate = useMemo(() => {
    const content = _.get(activityInfo, 'content', [])
    const convertTypeToRouterName = type => {
      return _.findKey(localToNext, v => v === type)
    }
    const handleContent = _.reduce(
      content,
      (init, value) => {
        const trueType = convertTypeToRouterName(value.type)
        const openLevel = _.filter(value.level, v => !v.endsWith('1'))
        if (!init.has(trueType)) init.set(trueType, new Set())
        _.forEach(openLevel, v => init.get(trueType).add(v))
        return init
      },
      new Map()
    )
    return handleContent
  }, [activityInfo])
  useEffect(() => {
    if (activityStatus) {
      setModalRedTips(currentOpenDeliberate.has(routerName))
    } else {
      setModalRedTips(false)
    }
  }, [activityStatus, currentOpenDeliberate, routerName])
  const sharedFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_WEBSITE_URL}`
    )
  }
  // 获取普通模态框排行数据
  const currentModalData = useMemo(() => {
    return (
      rank.get(`${localToNext[routerName]}_level_${diffcultLevel + 1}`) || []
    )
  }, [diffcultLevel, rank, routerName])
  // 获取模态当前分数
  const [modalLevel1Score, modalLevel2Score] = useMemo(() => {
    const key1 = `${localToNext[routerName]}_level_1`
    const key2 = `${localToNext[routerName]}_level_2`
    const level1 = _.get(currentRecordScore.get(key1), 'score', 0)
    const level2 = _.get(currentRecordScore.get(key2), 'score', 0)
    return [level1, level2]
  }, [currentRecordScore, routerName])
  return (
    <div
      className={css.a}
      style={{ ...style, overflow: !isVerticalScreen ? 'auto' : 'hidden' }}
    >
      <NavComponent position={'center'} backRouter={handleBackRouter}>
        <InsertButton cls={css.nav_name}>試煉場</InsertButton>
      </NavComponent>
      <div className={css.entry}>
        {activityStatus && (
          <ActivityIntroComponent
            status={isSignUp}
            setSignUpVisible={setSignUpVisible}
            activityInfo={activityInfo}
            currentUserRank={currentActivityUserRank}
            handleRankModal={handleRankModal}
          />
        )}
        <ul className={css.entry_container}>
          <li
            className={classNames(css.item, css.item1)}
            onClick={() => handleEntryModal('addState')}
          >
            <Lottie
              options={{
                ...defaultOptions,
                animationData: additionData.default
              }}
              width={360}
              height={360}
            />
            <div className={css.compose}>
              <img src={jia} alt="jia" className={css.flag} />
              {currentOpenDeliberate.has('addState') && (
                <img src={tagIcon} alt="tagIcon" className={css.activity} />
              )}
            </div>
          </li>
          <li
            className={classNames(css.item, css.item3)}
            onClick={() => handleEntryModal('subState')}
          >
            <Lottie
              options={{
                ...defaultOptions,
                animationData: subtractionData.default
              }}
              width={360}
              height={360}
            />
            <div className={css.compose}>
              <img src={jian} alt="jian" className={css.flag} />
              {currentOpenDeliberate.has('subState') && (
                <img src={tagIcon} alt="tagIcon" className={css.activity} />
              )}
            </div>
          </li>
          <li
            className={classNames(css.item, css.item2)}
            onClick={() => handleEntryModal('mulState')}
          >
            <Lottie
              options={{
                ...defaultOptions,
                animationData: multiplicationData.default
              }}
              width={360}
              height={360}
            />
            <div className={css.compose}>
              <img src={cheng} alt="cheng" className={css.flag} />
              {currentOpenDeliberate.has('mulState') && (
                <img src={tagIcon} alt="tagIcon" className={css.activity} />
              )}
            </div>
          </li>
          <li
            className={classNames(css.item, css.item4)}
            onClick={() => handleEntryModal('divState')}
          >
            <Lottie
              options={{
                ...defaultOptions,
                animationData: divisionData.default
              }}
              width={360}
              height={360}
            />
            <div className={css.compose}>
              <img src={chu} alt="chu" className={css.flag} />
              {currentOpenDeliberate.has('divState') && (
                <img src={tagIcon} alt="tagIcon" className={css.activity} />
              )}
            </div>
          </li>
        </ul>
      </div>
      <OnionModal visible={visible} type={'free'}>
        <div>
          <div className={css.model}>
            {modalRedTips && (
              <div className={css.broadcast}>
                參與活動期間，挑戰成績以活動“積分榜”為準
              </div>
            )}
            <div className={css.close} onClick={() => setVisible(false)} />
            <div className={css.tab}>
              <QuadrangleTab
                dec={'column'}
                tabData={['關 卡', '記 錄 榜']}
                changeTab={key => handleChangeRank(key)}
                changeValue={changeValue}
              />
            </div>
            {!chooseTab ? (
              <>
                <div className={css.head}>
                  <img
                    src={modalShowConfig.icon}
                    alt="icon"
                    className={css.icon}
                  />
                  <div className={css.title_block}>
                    <img src={modalShowConfig.title} alt="title" />
                    <div className={css.intro}>
                      {`在60s倒計時內，小朋友盡力完成${localToMethod[routerName]}運算相關的題目，回答優秀的話還會出現在得分榜中哦，加油吧~`}
                    </div>
                  </div>
                </div>
                <div className={css.dashed} />
                <ul className={css.entry_choose}>
                  <li className={css.one} onClick={() => handleModalRoute(1)}>
                    <div className={css.score}>{modalLevel1Score}</div>
                    <div className={css.score_text}>我的最高成績</div>
                    <img src={gos} alt="gos" className={css.go} />
                  </li>
                  <li className={css.two} onClick={() => handleModalRoute(2)}>
                    <div className={css.score}>{modalLevel2Score}</div>
                    <div className={css.score_text}>我的最高成績</div>
                    <img src={gos} alt="gos" className={css.go} />
                  </li>
                  <li className={css.three}>
                    <img src={tool} alt="tool" className={css.tool} />
                  </li>
                </ul>
              </>
            ) : (
              <>
                <div className={css.ww}>
                  <div className={css.tas}>
                    <QuadrangleTab
                      dec={'row'}
                      tabData={['關卡1', '關卡2', '關卡3']}
                      changeTab={(key, value) => handleChangeTab(key, value)}
                    />
                  </div>
                </div>
                {currentModalData.length > 0 ? (
                  <DiffcultLevelOne
                    personals={currentModalData}
                    currentRecordScore={_.get(
                      currentUserRank,
                      `level${diffcultLevel + 1}`,
                      0
                    )}
                    type={`${localToNext[routerName]}_level_${diffcultLevel +
                      1}`}
                  />
                ) : (
                  <div className={css.no_rank}>
                    <img src={noRank} alt="noRank" />
                  </div>
                )}
                <div className={css.bottom}>
                  <img src={ddd} alt="ddd" />
                </div>
              </>
            )}
          </div>
        </div>
      </OnionModal>
      <SignUpModal
        setSignUpVisible={setSignUpVisible}
        signUpVisible={signUpVisible}
        activityInfo={activityInfo}
        handleSignUp={handleSignUp}
        isSignUp={isSignUp}
      />
      <SignUpSuccess
        signUpSuccessVisible={signUpSuccessVisible}
        setSignUpSuccessVisible={setSignUpSuccessVisible}
        activityInfo={activityInfo}
      />
      <RankListComponent
        rankListVisible={rankListVisible}
        setRankListVisible={setRankListVisible}
        modalRankData={modalRankData}
        type={_.get(activityInfo, 'content.0.type', 'plus')}
        sharedFacebook={sharedFacebook}
        activityInfo={activityInfo}
      />
    </div>
  )
}
DeliberatePracticeEntry.propTypes = {}
export default connect()(memo(DeliberatePracticeEntry))
// TODO 优化记录点
// 1、字体加载问题优化
//    - 转成base64 + localStorage + js new Font
//    - font-display
// 2、图片加载问题优化  ---> 完事 有个warning -> 后续可以增加转场loading
// 3、路由跳转动画优化 ---> 取消
//   - 强刷怎么解决
//   - 懒加载怎么解决
// 4、类型系统优化 ---> 卡在增强dispatch那  想想怎么解决吧
// 5、长列表优化
// 6、awms代替js生成习题优化 ---> 意义不大 要使用就用C++吧 ts还需要摸索
// 7、indexDB 代替localstorage优化
// 8、组价手动输入优化
//
//
