import React from 'react'
import MoveBgAnimation from './MoveBgAnimation/MoveBgAnimation'
import StaticBgAnimation from './StaticBgAnimation/StaticBgAnimation'
const BGRef = new Map()
BGRef.set('none', null)
BGRef.set('static-onion', <StaticBgAnimation />)
BGRef.set('dynamic-onion', <MoveBgAnimation />)
const BackgroundAnimation = ({ showBg }) => {
  return <div>{BGRef.get(showBg)}</div>
}

export default BackgroundAnimation
