/**
 *  @Author PandaCure
 *  @DATE 2019/12/31
 *  @Project tw-website
 */
import React, { lazy, memo, useContext, useEffect, useState } from 'react'
import css from './index.module.scss'
import { connect, routerRedux, useDispatch, useSelector } from 'dva'
import ZzyDeliberatePractice from '@guanghe/deliberate-practice'
import '@guanghe/deliberate-practice/styles/zzy-deliberate-practice.scss'
import classNames from 'classnames'
import titleImage from '@/assets/deliberate-practice/detail-title.png'
import sumScore from '@/assets/deliberate-practice/detail-record.png'
import ZzyKatexMath from '@guanghe/katex-math'
import EnhanceMathquillNoEdit from 'enhance-mathquill-no-edit'
import timeImage from '@/assets/deliberate-practice/detail-time.png'
import NavComponent from '../DeliberatePracticeEntry/components/NavComponent/NavComponent'
import pathToRegexp from 'path-to-regexp'
import _ from 'lodash'
import buryPoint from '@/utils/buryPoint.utils'
import CountDownComponents from './components/CountDownComponents/CountDownComponents'
import { VerticalScreenContext } from '@/screens/DeliberatePractice/DeliberatePractice'
const OnionModal = lazy(() => import('@/components/OnionModal/OnionModal'))
const style = {
  position: 'absolute',
  left: 0,
  right: 0,
  width: '100vw',
  height: '100vh'
}
const localToNext = {
  addState: 'plus',
  subState: 'subtract',
  mulState: 'multi',
  divState: 'division'
}
const DeliberatePracticeDetail = () => {
  let [time, setTime] = useState(60)
  let [countDown, setCountDown] = useState(3)
  const [saveTime] = useState(new Date())
  const [visible, setVisible] = useState(false)
  const [quitVisible, setQuitVisible] = useState(false)
  const [timeModal, setTimeModal] = useState(true)
  const [newScore, setNewScore] = useState(false)
  const [modalScoreData, setModalScoreData] = useState({ data: [] })
  const [type, setType] = useState('addStateLevel1')
  const [userKeepAnswerQuestion, setUserKeepAnswerQuestion] = useState(0)
  const [userCorrectAnswerQuestion, setUserCorrectAnswerQuestion] = useState(0)
  const [
    userConsitionAnswerQuestion,
    setUserConsitionAnswerQuestion
  ] = useState(0)
  const [sum, setSum] = useState(0)
  // 获取注册状态
  const signUpStatus = useSelector(state => state.dp.signUpStatus)
  // 普通状态下的分数
  const currentRecordScore = useSelector(state => state.dp.currentRecordScore)
  // 竞赛下的分数
  const currentActivityRecordScore = useSelector(
    state => state.dp.currentActivityUserRank
  )
  const dispatch = useDispatch()
  const isVerticalScreen = useContext(VerticalScreenContext)
  useEffect(() => {
    const path = pathToRegexp('/deliberate-practice/detail/:id').exec(
      window.location.hash.slice(1)
    )
    let matchNumber
    let currentLevel
    if (typeof path[1] === 'string') {
      matchNumber = /(.*?)(\d*)$/.exec(path[1])
      if (Number(matchNumber[2]) > 1) {
        currentLevel = _.isNaN(Number(matchNumber[2]))
          ? 1
          : Number(matchNumber[2]) > 2
          ? 2
          : Number(matchNumber[2])
        setType(`${matchNumber[1]}Level${currentLevel}`)
      } else {
        setType(`${matchNumber[1]}Level1`)
      }
    }

    dispatch({
      type: 'dp/getCurrentPeopleScore',
      types: localToNext[path[1].slice(0, 8)],
      level: Array(currentLevel)
        .fill(1)
        .map((v, i) => `level${i + 1}`)
    })
    dispatch({
      type: 'dp/getActivityDetails'
    })
    if (window.localStorage.getItem('record-user-do-problem')) {
      window.localStorage.removeItem('record-user-do-problem')
    }
    return () => {
      window.localStorage.removeItem('record-user-do-problem')
    }
  }, [dispatch])
  const activityInfo = useSelector(state => state.dp.activityInfo)
  useEffect(() => {
    const getLocalData = () => {
      const data = window.localStorage.getItem('record-user-do-problem')
      if (!data) return { data: [] }
      const needComputerData = JSON.parse(data)
      setModalScoreData(needComputerData)
      // 连续答题20加的分数
      const keepAnswerQuestion =
        Math.floor(_.get(needComputerData, 'data.length', 0) / 20) * 5
      setUserKeepAnswerQuestion(keepAnswerQuestion)
      // 答对的分数
      const correctAnswerQuestion =
        _.chain(needComputerData)
          .get('correctArr', [])
          .filter(v => v)
          .get('length', 0)
          .value() * 5
      setUserCorrectAnswerQuestion(correctAnswerQuestion)
      // 连续答对分数
      const reduceData = _.get(needComputerData, 'correctArr', [])
      const consitionAnswerQuestion = reduceData
        .join('')
        .replace(/,/, '')
        .match(/(1+)/g)
        ?.reduce((init, value) => {
          if (value.length > 1) {
            init += ((1 + value.length) * value.length) / 2
          }
          return init
        }, 0)
      setUserConsitionAnswerQuestion(consitionAnswerQuestion || 0)
      const score =
        keepAnswerQuestion +
        (consitionAnswerQuestion || 0) +
        correctAnswerQuestion
      setSum(score)
      // if (currentRecordScore.score <= score) {
      const [, kind, level] = Array.from(type.match(/^([a-zA-Z]+)([0-9]+)$/))
      const currentType = localToNext[kind.slice(0, 8)]
      const haveCid = _.chain(activityInfo)
        .get('content', [])
        .find(v => v.type === currentType && ~v.level.indexOf(`level${level}`))
        .value()
      // 分别处理活动期间分数 和 非活动时间的答题分数
      let currentRecordScoreTargetLevel
      if (
        haveCid &&
        signUpStatus &&
        currentType === _.get(activityInfo, 'content.0.type', '')
      ) {
        currentRecordScoreTargetLevel = currentActivityRecordScore[
          `level${level}`
        ] || { score: 0 }
      } else {
        currentRecordScoreTargetLevel = currentRecordScore.get(
          `${currentType}_level_${level}`
        ) || { score: 0 }
      }
      setNewScore(currentRecordScoreTargetLevel.score < score)

      let params = {
        type: 'dp/updateSelfScore',
        types: currentType,
        score,
        level: 'level' + level || `level1`,
        duration: 60,
        name: currentType
      }
      params =
        haveCid && signUpStatus
          ? _.assign({}, params, { cid: _.get(activityInfo, 'id', undefined) })
          : params
      dispatch(params)
      // }
    }
    let timer
    if (!timeModal) {
      timer = setTimeout(() => {
        setTime(--time)
      }, 1000)
      if (time === 0) {
        clearTimeout(timer)
        getLocalData()
        setQuitVisible(false)
        setVisible(true)
      }
    }
    return () => clearTimeout(timer)
  }, [
    activityInfo,
    currentActivityRecordScore,
    currentRecordScore,
    currentRecordScore.score,
    dispatch,
    signUpStatus,
    time,
    timeModal,
    type
  ])
  const handleBackRouter = () => {
    if (time > 0 && time <= 60) setQuitVisible(true)
  }
  const handleQuitPage = () => {
    buryPoint({
      category: `Practicedetails_return_click`,
      action: 'Practicedetails'
    })
    dispatch(routerRedux.goBack())
  }
  useEffect(() => {
    const entryTime = window.localStorage.getItem('dp_entry') || 0
    if (window.localStorage.getItem('dp_detail')) {
      window.localStorage.removeItem('dp_detail')
      window.localStorage.removeItem('dp_entry')
    }
    return () => {
      window.localStorage.setItem(
        'dp_detail',
        new Date() - saveTime + entryTime + ''
      )
    }
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown(--countDown)
    }, 1000)
    if (countDown === 0) {
      setTimeModal(false)
    }
    return () => clearTimeout(timer)
  }, [countDown])
  return (
    <div
      className={classNames(css.a, 'math-quill-hack')}
      style={{ ...style, overflow: !isVerticalScreen ? 'auto' : 'hidden' }}
    >
      <NavComponent position={'flex-start'} backRouter={handleBackRouter}>
        <div className={css.timer}>
          <img src={timeImage} alt="timeImage" />
          倒計時
          <span className={css.big}>{time}</span>s
        </div>
      </NavComponent>
      <CountDownComponents time={time} type={type} />
      <div className={css.center}>
        {type.startsWith('addState') && (
          <ZzyDeliberatePractice types={type} key={type} />
        )}
        {type.startsWith('subState') && (
          <ZzyDeliberatePractice types={type} key={type} />
        )}
        {type.startsWith('mulState') && (
          <ZzyDeliberatePractice types={type} key={type} />
        )}
        {type.startsWith('divState') && (
          <ZzyDeliberatePractice types={type} key={type} />
        )}
      </div>
      <OnionModal visible={timeModal} type={'free'}>
        <div className={css.time_block}>
          <div className={css.number}>{countDown}</div>
          <div className={css.intro} />
        </div>
      </OnionModal>
      <OnionModal visible={visible} type={'free'}>
        <div className={css.model}>
          <div
            className={css.close}
            onClick={() => dispatch(routerRedux.goBack())}
          />
          <div className={css.model_detail}>
            <div className={css.title}>
              <img src={titleImage} alt="titleImage" />
            </div>
            <ul className={css.show_item}>
              <li className={css.item}>
                <span className={css.attr}>正確數量得分：</span>
                <span className={css.score}>+{userCorrectAnswerQuestion}</span>
              </li>
              <li className={css.item}>
                <span className={css.attr}>答題獎勵得分：</span>
                <span className={css.score}>+{userKeepAnswerQuestion}</span>
              </li>
              <li className={css.item}>
                <span className={css.attr}>連續正確獎勵：</span>
                <span className={css.score}>
                  +{userConsitionAnswerQuestion}
                </span>
              </li>
              <li className={css.dash_line} />
              <li className={css.item}>
                <span className={css.attr}>總分：</span>
                <div className={css.score1}>
                  {newScore && <img src={sumScore} alt="sumScore" />}
                  <span className={css.sum}>{sum}</span>
                </div>
              </li>
            </ul>
            <ul className={css.show_error_right}>
              {modalScoreData.data.map((v, i) => {
                const { resultExpression, answerMathExpression, expression } = v
                const isCorrect = resultExpression !== answerMathExpression
                return (
                  <li
                    key={i}
                    className={classNames(css.error_right, 'math-quill-hack')}
                  >
                    <ZzyKatexMath
                      mathExpression={expression}
                      handleKatexError={() => {}}
                    />
                    {isCorrect && (
                      <div className={css.error}>
                        <EnhanceMathquillNoEdit
                          mathExpression={answerMathExpression}
                        />
                      </div>
                    )}
                    <div
                      className={css.right}
                      style={{ marginLeft: !isCorrect ? '10px' : '' }}
                    >
                      <EnhanceMathquillNoEdit
                        mathExpression={resultExpression}
                      />
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </OnionModal>
      <OnionModal visible={quitVisible} type={'free'}>
        <div className={css.quit}>
          <div className={css.quit_close} onClick={() => setQuitVisible()} />
          <div className={css.quit_text} onClick={handleQuitPage} />
        </div>
      </OnionModal>
    </div>
  )
}
DeliberatePracticeDetail.propTypes = {}
export default connect()(memo(DeliberatePracticeDetail))
