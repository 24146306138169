const domain = {
  production: {
    websiteUrl: 'https://api.onion-academy.com'
  },
  test: {
    websiteUrl: 'http://study-api.dev.onion-academy.com'
  },
  development: {
    websiteUrl: 'http://study-api.dev.onion-academy.com'
  },
  preprod: {
    websiteUrl: 'https://api-test.onion-academy.com'
  }
}
const env = process.env.REACT_APP_DOMIN
export default domain[env]
