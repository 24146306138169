import React, { lazy, Suspense } from 'react'
import { createPortal } from 'react-dom'
import _ from 'lodash'
import Loading from '@/components/Loading.common'
const root = document.getElementById('root')
const CustomModal = lazy(() => import('./CustomModal/CustomModal'))
const FreeModal = lazy(() => import('./FreeModal/FreeModal'))
class OnionModal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    root.appendChild(this.el)
  }

  componentWillUnmount() {
    root.removeChild(this.el)
  }

  render() {
    const props = this.props
    const ModalShow =
      _.get(props, 'type', 'custom') === 'custom' ? (
        <CustomModal {...props} />
      ) : (
        <FreeModal {...props} />
      )
    return createPortal(
      <Suspense fallback={<Loading show inline />}>{ModalShow}</Suspense>,
      this.el
    )
  }
}
export default OnionModal
