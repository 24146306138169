import { getSectionInfo } from '@/services/homeServices'
import _ from 'lodash'

/**
 * courseId 课程ID
 * chapterId 章节ID
 */
export default {
  namespace: 'global',
  state: {
    chapterStatistics: {
      point: 0,
      totalPoint: 0,
      process: '0%',
      topicFinished: 0,
      questionDone: 0,
      practiceNum: 0,
      topicNum: 0,
      correctRate: 0,
      focusRate: 0,
      processNum: 0
    },
    topicsList: [],
    currentChooseOptInfo: {
      courseId: '',
      chapterId: ''
    },
    currentChooseTopic: {},
    chapterDetails: {},
    videoAndPracticeRecordStatus: ''
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(async ({ pathname }) => {
        try {
        } catch (err) {}
      })
    }
  },
  effects: {
    *getChooseChapterDetails(
      { courseId, chapterId, topicId, practiceIndex },
      { call, put, select }
    ) {
      const currentChooseCourseInfo = yield select(
        state => state.global.currentChooseOptInfo
      )
      const chooseCourseId = courseId || currentChooseCourseInfo.courseId
      const chapterResult = yield call(getSectionInfo, chapterId, {
        courseId: chooseCourseId
      })
      let process = _.get(chapterResult, 'process', 0)
      if (process && _.isNumber(process)) {
        process = (process * 100).toFixed(1) + '%'
      } else if (process && _.isString(process)) {
        process = parseInt(process, 10).toFixed(1) * 100 + '%'
      } else {
        process = '0%'
      }
      yield put({
        type: 'updateChapterStatistics',
        statistics: {
          point: _.get(chapterResult, 'point', 0),
          totalPoint: _.get(chapterResult, 'totalPoint', 0),
          topicFinished: _.get(chapterResult, 'topicFinished', 0),
          questionDone: _.get(chapterResult, 'questionDone', 0),
          practiceNum: _.get(chapterResult, 'practiceNum', 0),
          topicNum: _.get(chapterResult, 'topicNum', 0),
          correctRate: _.get(chapterResult, 'correctRate', 0),
          focusRate: _.get(chapterResult, 'focusRate', 0),
          lecture: _.get(chapterResult, 'lecture', { url: '' }),
          process,
          processNum: _.get(chapterResult, 'process', 0)
        }
      })
      const topics = _.get(chapterResult, 'topics', [])
      // 获取测验
      const examination = [...topics].pop()
      if (_.get(examination, 'scene', '') === 'examination') {
        yield put({
          type: 'home/getExaminationData',
          courseId: chooseCourseId,
          chapterId,
          id: _.get(examination, 'id', ''),
          isExist: true
        })
      } else {
        yield put({
          type: 'home/getExaminationData',
          isExist: false
        })
      }
      yield put({
        type: 'updateTopicsList',
        topics: topics
      })
      yield put({
        type: 'currentChooseOptInfo',
        chapterId,
        courseId
      })
      const chooseTopicInfo = _.find(topics, value => value.id === topicId)
      if (topicId) {
        const videoId = _.get(chooseTopicInfo, 'video.id', '')
        yield put({
          type: 'chooseTopic',
          topicInfo: chooseTopicInfo
        })
        videoId &&
          (yield put({
            type: 'video/chooseTopicVideo',
            courseId,
            chapterId,
            topicId,
            videoId
          }))
      }
      const currentIndex =
        practiceIndex === null ? 0 : parseInt(practiceIndex, 10)
      if (topicId && currentIndex >= 0) {
        const problemIdsGroup = _.get(chooseTopicInfo, 'problemIdsGroup', [])
        yield put({
          type: 'practice/chooseTopicPractice',
          problemIdsGroup
        })
        yield put({
          type: 'practice/chooseTopicDoPractice',
          courseId,
          chapterId,
          topicId,
          currentIndex
        })
      }
      yield put({
        type: 'ui/homePageLoading',
        loading: false
      })
    },
    *currentChooseOptInfo({ courseId, chapterId }, { put }) {
      yield put({
        type: 'updateCurrentChooseOptInfo',
        chapterId,
        courseId
      })
    },
    *chooseTopic({ topicInfo }, { put }) {
      yield put({
        type: 'updateChooseTopicInfo',
        topicInfo
      })
    }
  },
  reducers: {
    updateChapterStatistics(state, { statistics }) {
      return {
        ...state,
        chapterStatistics: {
          ...statistics
        }
      }
    },
    updateTopicsList(state, { topics }) {
      return {
        ...state,
        topicsList: topics
      }
    },
    updateCurrentChooseOptInfo(state, { courseId, chapterId }) {
      return {
        ...state,
        currentChooseOptInfo: {
          ...state.currentChooseOptInfo,
          chapterId,
          courseId
        }
      }
    },
    updateChooseTopicInfo(state, { topicInfo }) {
      return {
        ...state,
        currentChooseTopic: {
          ...topicInfo
        }
      }
    },
    chooseChapterDetails(state, { value }) {
      return {
        ...state,
        chapterDetails: value
      }
    },
    recordVideoAndPracticeStatus(state, { status }) {
      return {
        ...state,
        videoAndPracticeRecordStatus: status
      }
    }
  }
}
