import { networkUrl } from '@/utils/network.utils'
import domains from '@/utils/networkConfig'
const APIs = {
  websiteUrl: {
    getDeliberatePracticePeopleScore: '/records/personal/score',
    getDeliberatePracticeRank: '/records/personal/rank',
    getCurrentActivityInfo: '/competitions/current',
    signUp: '/competitions/:id/sign',
    checkSignUp: '/competitions/:id/check'
  }
}
export default networkUrl(APIs, domains)
