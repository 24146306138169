import { getPracticeDetails, getSectionInfo } from '@/services/homeServices'
import { getAnswerboardExam, getSummaryExam } from '@/services/examServices'
import _ from 'lodash'
export default {
  namespace: 'examination',
  state: {
    problemDetail: {},
    currentDataInfo: {},
    examLoading: true,
    conditionLoading: true,
    questionIndex: 0,
    problemIdsGroup: [],
    summary: {},
    answerBoard: [],
    topics: [],
    problemData: {}, // 展示题目
    examinationData: {}
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(async ({ pathname }) => {
        try {
          dispatch({
            type: 'changeLoadingState'
          })
        } catch (err) {}
      })
    }
  },
  effects: {
    *getExamDetailAndProblemDetails(
      { courseId, chapterId, examId, index },
      { call, put, select }
    ) {
      let questionIndex
      if (!index) {
        questionIndex = 0
      } else {
        questionIndex = parseInt(index, 10)
      }
      let examinationData = yield select(state => state.home.examinationData)
      if (Object.keys(examinationData).length < 5) {
        const result = yield put({
          type: 'home/getExaminationData',
          chapterId,
          courseId,
          id: examId,
          isExist: true
        })
        yield result.then(data => (examinationData = data))
      }
      const problemIdsGroup = _.get(examinationData, 'problemIdsGroup', [[]])
      const result = yield call(
        getPracticeDetails,
        problemIdsGroup[questionIndex][0],
        {
          chapterId,
          courseId,
          topicId: examId
        }
      )
      yield put({
        type: 'getProblemDetail',
        problemDetail: result,
        questionIndex,
        currentDataInfo: {
          chapterId,
          courseId,
          topicId: examId
        },
        problemIdsGroup,
        examinationData
      })
      return problemIdsGroup
    },
    *getConditionData(
      { courseId, chapterId, examId, kind },
      { call, put, select, all }
    ) {
      yield put({
        type: 'changeLoadingState'
      })
      let problemIdsGroup = yield select(
        state => state.examination.problemIdsGroup
      )
      if (_.isArray(problemIdsGroup) && problemIdsGroup.length <= 0) {
        const result = yield put({
          type: 'getExamDetailAndProblemDetails',
          chapterId,
          courseId,
          examId
        })
        yield result.then(data => (problemIdsGroup = data))
      }
      const summary = yield call(getSummaryExam, examId, {
        courseId,
        chapterId,
        type: kind
      })
      const answerBoard = yield call(getAnswerboardExam, {
        courseId,
        chapterId,
        type: kind,
        topicId: examId
      })
      const chapterList = yield call(getSectionInfo, chapterId, { courseId })
      yield put({
        type: 'updateConditionData',
        summary,
        answerBoard,
        topics: _.get(chapterList, 'topics', [])
      })
    },
    *getInitProblemData(
      { problemId, answer, topicId, pass },
      { select, call, put }
    ) {
      const currentDataInfo = yield select(
        state => state.examination.currentDataInfo
      )
      const result = yield call(getPracticeDetails, problemId, currentDataInfo)
      yield put({
        type: 'updateInitProblemData',
        problemData: {
          [problemId]: {
            ...result,
            answers: JSON.parse(answer),
            correct: pass
          }
        }
      })
    }
  },
  reducers: {
    getProblemDetail(
      state,
      {
        problemDetail,
        questionIndex,
        currentDataInfo,
        problemIdsGroup,
        examinationData
      }
    ) {
      return {
        ...state,
        problemDetail,
        examLoading: false,
        questionIndex,
        currentDataInfo: {
          ...currentDataInfo
        },
        problemIdsGroup,
        examinationData
      }
    },
    changeLoadingState(state) {
      return {
        ...state,
        examLoading: true,
        conditionLoading: true
      }
    },
    updateConditionData(state, { summary, answerBoard, topics }) {
      return {
        ...state,
        summary,
        answerBoard,
        conditionLoading: false,
        topics
      }
    },
    updateInitProblemData(state, { problemData }) {
      return {
        ...state,
        problemData: {
          ...state.problemData,
          ...problemData
        }
      }
    }
  }
}
