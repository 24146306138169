import _ from 'lodash'
import { routerRedux } from 'dva'
import { getPracticeDetails, clearQuestions } from '@/services/homeServices'
import { convertTextToAudioServer } from '@/services/otherServices'

export default {
  namespace: 'practice',
  state: {
    practiceList: [],
    practiceDetails: {},
    finishedPracticeDetails: {},
    audioText: new Map(),
    buffer: null,
    stop: false
  },
  effects: {
    *chooseTopicPractice({ problemIdsGroup }, { put }) {
      const practiceList = _.flatten(problemIdsGroup)
      yield put({
        type: 'updatePracticeList',
        practiceList
      })
    },
    *chooseTopicDoPractice(
      { courseId, chapterId, topicId, currentIndex },
      { call, put, select }
    ) {
      const practiceList = yield select(state => state.practice.practiceList)
      const currentChooseOptInfo = yield select(
        state => state.global.currentChooseOptInfo
      )
      const { id } = yield select(state => state.global.currentChooseTopic)
      const practiceSum = _.get(practiceList, 'length', 0)
      if (practiceSum === currentIndex) {
        // yield put(routerRedux.push('/home/practice/finished'))
        yield put(
          routerRedux.push(
            `/home/course/${currentChooseOptInfo.courseId}/chapter/${currentChooseOptInfo.chapterId}/topic/${id}/practice/finished`
          )
        )
      } else {
        const practiceId = practiceList[currentIndex]
        const params = {
          courseId,
          chapterId,
          topicId
        }
        const practiceDetails = yield call(
          getPracticeDetails,
          practiceId,
          params
        )
        yield put({
          type: 'updatePracticeInfo',
          practiceDetails: {
            practiceDetails,
            currentIndex
          }
        })
      }
    },
    *getSinglePracticeDetail(
      { courseId, chapterId, topicId, practiceId },
      { call, put }
    ) {
      const params = {
        courseId,
        chapterId,
        topicId
      }
      const practiceDetails = yield call(getPracticeDetails, practiceId, params)
      yield put({
        type: 'updateSinglePracticeDetail',
        practiceDetails
      })
    },
    *clearQuestions({ courseId, chapterId, topicId, ...kinds }, { call, put }) {
      const type = kinds.click_type
      const problemType = kinds.problemType
      let routerStr
      if (type === 'topic') {
        problemType === 'new'
          ? (routerStr = `/new-practice/course/${courseId}/chapter/${chapterId}/topic/${topicId}`)
          : (routerStr = `/home/course/${courseId}/chapter/${chapterId}/topic/${topicId}/practice`)
      } else if (type === 'chapter') {
        routerStr = `/home/course/${courseId}/chapter/${chapterId}/exam/${topicId}/examination`
      }
      try {
        const result = yield call(clearQuestions, {
          chapterId,
          courseId,
          topicId,
          type
        })
        if (result) {
          yield put(routerRedux.push(routerStr))
        }
      } catch (e) {
        // TODO 提示清除错误
        console.log(e)
      }
    },
    *convertTextToAudioEffect({ audioText }, { call, put, select }) {
      const oldAudioText = yield select(({ practice }) => practice.audioText)
      let AudioStream
      if (oldAudioText.has(audioText)) {
        AudioStream = oldAudioText.get(audioText)
      } else {
        const reqData = yield call(convertTextToAudioServer, {
          text: audioText
        })
        AudioStream = reqData.AudioStream
      }
      yield put({
        type: 'updateAudioBuffer',
        audioText,
        AudioStream
      })
      yield put({
        type: 'stopAudio',
        stop: false
      })
    }
  },
  reducers: {
    updatePracticeList(state, { practiceList }) {
      return {
        ...state,
        practiceList: [...practiceList]
      }
    },
    updatePracticeInfo(state, { practiceDetails }) {
      return {
        ...state,
        practiceDetails: {
          practice: practiceDetails.practiceDetails,
          currentIndex: practiceDetails.currentIndex
        }
      }
    },
    updateSinglePracticeDetail(state, { practiceDetails }) {
      return {
        ...state,
        finishedPracticeDetails: practiceDetails
      }
    },
    removePracticeList(state) {
      return {
        ...state,
        practiceList: []
      }
    },
    clearPracticeBody(state) {
      return {
        ...state,
        practiceDetails: {
          ...state.practiceDetails,
          practice: Object.create({ body: '' })
        }
      }
    },
    updateAudioBuffer(state, { audioText, AudioStream }) {
      const cacheMap = new Map()
      cacheMap.set(audioText, AudioStream)
      state.audioText.forEach((value, key) => {
        cacheMap.set(key, value)
      })
      console.log(cacheMap)
      return {
        ...state,
        audioText: cacheMap,
        buffer: AudioStream.data
      }
    },
    stopAudio(state, { stop }) {
      return {
        ...state,
        stop
      }
    }
  }
}
