import { lazy } from 'react'
const routes = [
  {
    path: '/mobile/continue-online-learning',
    component: lazy(() => import('@/mobile/Phone/Phone'))
  },
  {
    path: '*',
    component: lazy(() => import('@/mobile/Phone/Phone'))
  }
]
export default routes
