/**
 *  @Author PandaCure
 *  @DATE 2020/4/20
 *  @Project tw-website
 */
import React, { memo, useMemo } from 'react'
import css from './index.module.scss'
import { connect } from 'dva'
import PropTypes from 'prop-types'
import iconMessage from '@/assets/deliberate-practice/ic_message.png'
import iconSimple from '@/assets/deliberate-practice/simple_icon.png'
import iconDiffcult from '@/assets/deliberate-practice/diffcult_icon.png'
import iconButton from '@/assets/deliberate-practice/button_icon.png'
import styled from 'styled-components'
import cs from 'classnames'
import _ from 'lodash'
const ButtonIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${iconButton});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
`
const TopLeftButtonIcon = styled(ButtonIcon)`
  left: 0;
  top: 0;
`
const TopRightButtonIcon = styled(ButtonIcon)`
  right: 0;
  top: 0;
`
const BottomLeftButtonIcon = styled(ButtonIcon)`
  left: 0;
  bottom: 0;
`
const BottomRightButtonIcon = styled(ButtonIcon)`
  right: 0;
  bottom: 0;
`
const localToText = {
  plus: '加法',
  subtract: '減法',
  multi: '乘法',
  division: '除法'
}
const ActivityIntroComponent = ({
  status,
  setSignUpVisible,
  activityInfo,
  currentUserRank,
  handleRankModal
}) => {
  const [days, hours, minutes] = useMemo(() => {
    const beginTime = new Date()
    const endTime = _.get(activityInfo, 'endTime', new Date())
    const currTime = new Date(beginTime).getTime()
    const futureTime = new Date(endTime).getTime()
    const day = Math.floor((futureTime - currTime) / 1000 / 60 / 60 / 24)
    let minute = null
    const hour = Math.floor(
      ((futureTime - currTime) / 1000 / 60 / 60 / 24 - day) * 24
    )
    if (day === 0) {
      minute = Math.floor((futureTime - currTime) / 1000 / 60 / 60 - hour * 60)
    }
    return [day, hour, minute]
  }, [activityInfo])
  const type = useMemo(() => {
    const key = _.get(activityInfo, 'content.0.type', 'plus')
    return localToText[key]
  }, [activityInfo])
  const currentType = useMemo(() => {
    return _.get(activityInfo, 'content.0.type', 'plus')
  }, [activityInfo])
  const currentLevel = useMemo(() => {
    return _.get(activityInfo, 'content.0.level', ['level1', 'level2'])
  }, [activityInfo])
  const currentOpenGroup = useMemo(() => {
    const level = _.get(activityInfo, 'content.0.level', [])
    return level.reduce((init, value) => {
      init.set(value, value)
      return init
    }, new Map())
  }, [activityInfo])
  return (
    <div className={css.active_info}>
      <TopLeftButtonIcon />
      <TopRightButtonIcon />
      <BottomLeftButtonIcon />
      <BottomRightButtonIcon />
      <div className={css.left}>
        <div className={css.intro_title}>
          {status
            ? `您已參加【${type}試煉爭霸賽】的距離活動結束還有` +
              (days > 0 ? `${days}天` : '') +
              `${hours}小時` +
              (minutes > 0 ? `${minutes}分` : '')
            : `${type}試煉場爭霸賽正在火熱進行中，參與贏好禮`}
        </div>
        {status ? (
          <div className={css.button_block}>
            <button
              className={cs(css.watch, css.small)}
              onClick={() => setSignUpVisible(true)}
            >
              <img src={iconMessage} alt="iconMessage" />
              活動詳情
            </button>
            <button
              className={cs(css.watch, css.green)}
              onClick={() => handleRankModal(currentType, currentLevel)}
            >
              <img src={iconMessage} alt="iconMessage" />
              積分榜
            </button>
          </div>
        ) : (
          <button className={css.watch} onClick={() => setSignUpVisible(true)}>
            <img src={iconMessage} alt="iconMessage" />
            查看活動詳情及獎品信息
          </button>
        )}
      </div>
      <ul className={css.right}>
        {status ? (
          <>
            {currentOpenGroup.has('level1') && (
              <li className={css.people}>
                <div className={css.num}>
                  <span className={css.unit}>#</span>
                  {_.get(currentUserRank, 'level1.rank', '')}
                </div>
                <div className={css.num_intro}>
                  我的最高成績:{_.get(currentUserRank, 'level1.score', 0)}
                </div>
                <img src={iconSimple} alt="iconSimple" className={css.icon} />
              </li>
            )}
            {currentOpenGroup.has('level2') && (
              <li className={css.days}>
                <div className={css.time}>
                  <span className={css.unit}>#</span>
                  {_.get(currentUserRank, 'level2.rank', '')}
                </div>
                <div className={css.num_intro}>
                  我的最高成績:{_.get(currentUserRank, 'level2.score', '')}
                </div>
                <img
                  src={iconDiffcult}
                  alt="iconDiffcult"
                  className={css.icon}
                />
              </li>
            )}
          </>
        ) : (
          <>
            <li className={css.people}>
              <div className={css.num}>
                {_.get(activityInfo, 'total', 1000)}
              </div>
              <div className={css.num_intro}>當前參與人數</div>
            </li>
            <li className={css.days}>
              <div className={css.time}>
                {days > 0 && (
                  <>
                    {days}
                    <span className={css.unit}>天</span>
                  </>
                )}
                {hours}
                <span className={css.unit}>小時</span>
                {minutes > 0 && (
                  <>
                    {minutes}
                    <span className={css.unit}>分</span>
                  </>
                )}
              </div>
              <div className={css.num_intro}>活動倒計時</div>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
ActivityIntroComponent.propTypes = {
  status: PropTypes.bool.isRequired,
  setSignUpVisible: PropTypes.func.isRequired,
  handleRankModal: PropTypes.func.isRequired,
  activityInfo: PropTypes.object.isRequired,
  currentUserRank: PropTypes.object.isRequired
}
export default connect()(memo(ActivityIntroComponent))
